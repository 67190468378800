import { Depths, FontSizes, FontWeights, SharedColors } from '@fluentui/react'
import { CSSProperties } from 'react'
import styled from 'styled-components'

type Props = {
  children: React.ReactNode
  style?: CSSProperties
}

const StyledSpan = styled.span`
  color: white;
  padding: 0.25em 0.5em;
  width: fit-content;
  border-radius: 4px;
  font-size: ${FontSizes.size14};
  box-shadow: ${Depths.depth8};
  background-color: ${SharedColors.cyanBlue10};
  border: 1px solid ${SharedColors.cyanBlue10};
  font-weight: ${FontWeights.semibold};
`

export const TextBadge: React.FC<Props> = (props: Props) => {
  return <StyledSpan {...props}>{props.children}</StyledSpan>
}
