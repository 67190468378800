import { useContext, useEffect, useRef, useState } from 'react'
import {
  Checkbox,
  DefaultButton,
  ICheckboxProps,
  List,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  SharedColors,
  Stack,
  ChoiceGroup,
  IChoiceGroupOption,
} from '@fluentui/react'
import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'

import {
  Resource,
  PlaybooksBuilderRequest,
  PlaybooksBuilderResponse,
  PlaybookBuilderStatusResponse,
  RESOURCETYPES_OBJ,
  getDefaultAccess,
  AccessControlLevel,
} from '@blaw/contracts-api-schema'
import { ContractContext } from '@contexts/ContractContext'
import { delay, friendlyDate } from '@modules/utils'
import { KeyTermsContext } from '@contexts/KeyTermsContext'
import { PaginationFooterProps } from '@hooks/usePagination'
import { StoreContext } from '@contexts/StoreContext'
import { TemplatesContext } from '@contexts/TemplatesContext'
import { itemClicked, useContractTaskPaneViewed } from '@modules/analytics'
import { useTranslation } from '@hooks/useTranslation'
import ApiClient from '@modules/ApiClient'
import BoldText from '@baseComponents/BoldText'
import ErrorMessage from '@components/ErrorMessage'
import FacetGroups from '@components/FacetGroups'
import KeywordSearch from '@components/KeywordSearch'
import LinkButton from '@components/LinkButton'
import LoadingShimmer from '@components/LoadingShimmer'
import ModalProgress from '@components/ModalProgress'
import NotificationBadge from '@components/NotificationBadge'
import RequiredFieldMark from '@baseComponents/RequiredFieldMark'
import ResourceTitle from '@components/ResourceTitle'
import StyledStack from '@components/StyledStack'
import StyledTextField from '@components/StyledTextField'
import Text from '@baseComponents/Text'
import TopNav from '@components/TopNav'
import useLoadingFadeStyle from '@hooks/useLoadingFadeStyle'
import useSortedFacetGroups from '@hooks/useSortedFacetGroups'

export const playbookTemplatesPerPage = 5
const pageTitle = 'Playbook Builder'
enum CreationType {
  AI = 'ai',
  MANUAL = 'manual',
}

export default function PlaybookBuilder() {
  const [creating, setCreating] = useState(false)
  const [hideProgress, setHideProgress] = useState(true)
  const [error, setError] = useState('')
  const [title, setTitle] = useState('')
  const [partyRole, setPartyRole] = useState('')
  const [templateId, setSelectedTemplate] = useState<string | null>(null)
  const { t } = useTranslation()
  const [creationType, setCreationType] = useState('')
  const navigate = useNavigate()
  const {
    cancelFilters,
    error: templatesError,
    facetLabels,
    filterState,
    handleFiltering,
    items: { facets },
    loading,
    numFilters,
    Pagination,
    query,
    search,
    sortOrder,
    templates,
    warn,
  } = useContext(TemplatesContext)
  const { storeSessionInfo, sessionInfo, routes } = useContext(StoreContext)
  const { loadingMetadataConfig } = useContext(KeyTermsContext)
  const formRef = useRef<HTMLFormElement>(null)
  const { contract, getContractTypeFacet, loading: contractLoading } = useContext(ContractContext)
  const facetGroup = useSortedFacetGroups(facets, facetLabels, filterState, sortOrder).filter(
    g => g.field === 'contract_type',
  )
  const isLoading = loading || contractLoading || loadingMetadataConfig || creating
  const formInvalid = () =>
    isLoading ||
    (!templateId && creationType === CreationType.AI) ||
    !formRef.current?.checkValidity()
  const loadingFade = useLoadingFadeStyle(isLoading)
  const stackStyles = { marginTop: '1em', ...loadingFade }

  const choiceOptions: IChoiceGroupOption[] = [
    {
      key: CreationType.AI,
      text: 'Create With Template',
    },
    { key: CreationType.MANUAL, text: 'Create Manually' },
  ]

  type PostResourceResponse = {
    id: string
    version: string
  }

  useContractTaskPaneViewed({ pageTitle })

  useEffect(() => {
    loading && setSelectedTemplate(null)
  }, [loading])

  useEffect(() => {
    query || cancelFilters()
  }, [query])

  useEffect(() => {
    if (contractLoading || !contract) return

    const typeFacet = getContractTypeFacet(facets)
    if (typeFacet) handleFiltering('contract_type', typeFacet.value, true)
  }, [contractLoading, contract])

  return (
    <form onSubmit={submit} ref={formRef}>
      <TopNav title={pageTitle} prevPath="#/playbooks" showAIBadge />
      {creationType !== '' && <MessageBar>{getMessageByCreationType(creationType)}</MessageBar>}
      <ErrorMessage message={warn} type={MessageBarType.warning} />

      <StyledStack>
        <Stack.Item>
          {isLoading && facetGroup.length === 0 && <LoadingShimmer size={3} />}
          <FacetGroups
            required
            multiSelect={false}
            facetsMinimized={false}
            group={facetGroup}
            facetLabels={facetLabels}
            filterState={filterState}
            loading={isLoading || isLoading}
            styles={{ padding: 0 }}
            onRenderLabel={_ =>
              renderContractTypeFilterLabel(numFilters(), t('label.contract-type'))
            }
            // @ts-ignore TODO: TemplatesContext doesn't match the context prop type, need to fix
            context={TemplatesContext}
          />
        </Stack.Item>

        <Stack.Item style={{ marginBottom: '0.5em' }}>
          {isLoading && <LoadingShimmer size={3} />}
          <ChoiceGroup
            required={true}
            options={choiceOptions}
            label="Creation Type"
            disabled={isLoading}
            onChange={(_ev, option) => {
              option && setCreationType(option.key)
            }}
          />
        </Stack.Item>
        {creationType === CreationType.AI && (
          <Stack.Item>
            <Stack.Item style={{ ...stackStyles }}>
              <BoldText
                style={{ margin: '0 0.3em 0.3em 0', display: 'inline-block', ...loadingFade }}
              >
                {t('label.template')} <span style={{ color: SharedColors.red20 }}>*</span>
              </BoldText>
              {templateId && <NotificationBadge>1</NotificationBadge>}

              <KeywordSearch
                autoFocus
                query={query}
                loading={isLoading}
                search={search}
                placeholder={t('placeholder.templates-search')}
              />
            </Stack.Item>

            <Stack.Item style={stackStyles}>
              {isLoading && templates.length === 0 && <LoadingShimmer size={3} />}
              <Pagination Footer={p => <PaginationFooter {...p} templatesLoading={loading} />}>
                <List
                  key={templateId}
                  items={templates}
                  onRenderCell={(item, i) => (
                    <TemplateCheckbox
                      index={i}
                      item={item}
                      templateId={templateId}
                      checked={item && templateId === item.id}
                      onChange={(_e, checked) =>
                        item && setSelectedTemplate(checked ? item.id : null)
                      }
                    />
                  )}
                  onShouldVirtualize={() => false}
                  style={{ ...loadingFade }}
                />
              </Pagination>
            </Stack.Item>
          </Stack.Item>
        )}

        <Stack.Item style={{ ...stackStyles, marginBottom: '0.5em' }}>
          <StyledTextField
            required
            label={t('page.Playbook Builder.Playbook Name')}
            placeholder="A descriptive title"
            value={title}
            disabled={isLoading}
            onChange={(_e, value) => setTitle(value ?? '')}
          />
        </Stack.Item>

        <Stack.Item style={{ ...stackStyles, marginBottom: '0.5em' }}>
          <StyledTextField
            required
            label={t('page.Playbook Builder.Party Role')}
            placeholder={t('placeholder.Party Role')}
            value={partyRole}
            disabled={isLoading}
            onChange={(_e, value) => setPartyRole(value ?? '')}
          />
        </Stack.Item>

        <Stack.Item style={stackStyles}>
          <Stack horizontal style={{ justifyContent: 'end', gap: '1em' }}>
            <PrimaryButton
              type="submit"
              title={formInvalid() ? t('modal.Is Invalid') : ''}
              disabled={formInvalid()}
              onClick={submit}
              style={{ flexGrow: 1 }}
            >
              {t('button.Build Playbook')}
            </PrimaryButton>
            <DefaultButton disabled={isLoading} onClick={() => navigate(-1)}>
              {t('button.Cancel')}
            </DefaultButton>
          </Stack>
        </Stack.Item>

        <ModalProgress
          title={error ? 'An error occured' : 'Your playbook is being created'}
          description="Depending on complexity, this may take a few minutes..."
          error={error}
          hidden={hideProgress}
          cancelBtn={error ? 'Close' : false}
          onDismiss={() => setHideProgress(true)}
        />
      </StyledStack>
      <input type="submit" style={{ display: 'none' }} />
    </form>
  )

  async function createPlaybookWithAi(templateId: string): Promise<string> {
    const apiClient = new ApiClient(storeSessionInfo, setError)
    const payload: Partial<PlaybooksBuilderRequest> = {
      name: title,
      playbookTemplateIds: [templateId],
      contractType: filterState['contract_type'][0],
      resourceType: 'library_playbook',
      playbookPartyRole: partyRole,
      playbookStatus: 'DRAFT',
    }

    const { data } = await apiClient.post<PlaybooksBuilderResponse>(
      routes.playbooksBuilderUrl,
      payload,
    )
    let builderStatus: PlaybookBuilderStatusResponse = { isCompleted: false }
    const interval = 5000 // 5 second interval

    while (!(builderStatus.isCompleted && builderStatus.playbookInfo)) {
      const { data: status } = await apiClient.get<PlaybookBuilderStatusResponse>(
        routes.getPlaybooksBuilderStatusUrl(data.requestId),
      )
      builderStatus = status
      await delay(interval)
    }
    return builderStatus.playbookInfo.playbookId
  }

  async function createEmptyPlaybook(): Promise<string> {
    const apiClient = new ApiClient(storeSessionInfo, setError)
    const payload = {
      name: title,
      content: '{}',
      mimeType: 'application/json',
      accessControls: getDefaultAccess(AccessControlLevel.PUBLIC, sessionInfo.uuid || ''),
      metadata: {
        userMetadata: {
          contract_type: filterState['contract_type'][0],
          playbook_party_role: partyRole,
          playbook_status: 'DRAFT',
        },
        customMetadata: {},
        systemMetadata: {},
      },
      resourceType: RESOURCETYPES_OBJ.MY_PLAYBOOKS,
    }

    const { data } = await apiClient.post<PostResourceResponse>(routes.resourcesUrl, payload)
    return data.id
  }

  async function submit(e: { preventDefault: () => void }) {
    e.preventDefault()
    if (!formRef.current?.checkValidity()) return

    itemClicked({
      pageTitle: pageTitle,
      itemClicked: t('button.Build Playbook'),
      itemLocation: 'Taskpane',
      itemType: 'Form Submit',
      isLoggedIn: true,
    })
    setCreating(true)
    setHideProgress(false)
    setError('')

    try {
      let playbookId = ''
      if (creationType === CreationType.AI && templateId) {
        playbookId = await createPlaybookWithAi(templateId)
      } else if (creationType === CreationType.MANUAL) {
        playbookId = await createEmptyPlaybook()
      }

      navigate(`/playbooks/${playbookId}/edit?name=${title}`)
    } catch (e) {
      setError((e as Error).message)
      setCreating(false)
    }
  }
}

type TemplateCheckboxProps = ICheckboxProps & {
  templateId: string | null
  item?: Resource
  index?: number
}

function TemplateCheckbox({ index, item, checked, onChange, templateId }: TemplateCheckboxProps) {
  return (
    <Stack
      horizontal
      style={{
        width: '100%',
        alignItems: 'center',
        marginBottom: '0.5em',
        justifyContent: 'space-between',
      }}
    >
      <Checkbox
        required={index === 0 && !templateId}
        checked={checked}
        onChange={onChange}
        onRenderLabel={() => (item && <ResourceTitle item={item} />) || null}
      />
      {item && (
        <Text variant="small">{item.updated ? friendlyDate(item.updated) : t('label.N/A')}</Text>
      )}
    </Stack>
  )
}

function PaginationFooter({
  loading,
  loadMore,
  onLastPage,
  numHitsRangeLabel,
  templatesLoading,
}: PaginationFooterProps & { templatesLoading: boolean }) {
  const isLoading = loading || templatesLoading
  return (
    <Stack horizontal style={{ justifyContent: 'space-between', opacity: isLoading ? 0.5 : 1 }}>
      <LinkButton disabled={isLoading || onLastPage} onClick={loadMore}>
        {isLoading ? 'Loading...' : 'Show more'}
      </LinkButton>
      <Text>{numHitsRangeLabel}</Text>
    </Stack>
  )
}

function renderContractTypeFilterLabel(numFilters: number, label: string) {
  return (
    <Stack horizontal style={{ gap: '0.5em' }}>
      <BoldText>
        {label} <RequiredFieldMark />
      </BoldText>
      {numFilters > 0 && <NotificationBadge>{numFilters}</NotificationBadge>}
    </Stack>
  )
}

function getMessageByCreationType(creationType: string) {
  if (creationType === CreationType.AI)
    return 'Select a contract template and provide optional rules and guidance below to create a Playbook.'
  if (creationType === CreationType.MANUAL)
    return 'Please add at least 3 rules to create a playbook'
  return ''
}
