import { CSSProperties } from 'react'
import { Icon, Label } from '@fluentui/react'

import ShowMore from '@components/ShowMore'

interface Props {
  label: string
  content?: string
  icon?: string
  styles?: {
    root?: CSSProperties
    icon?: CSSProperties
  }
}

export default function LongTextWithLabel(props: Props) {
  const { label, content, icon, styles } = props
  if (!content) return null

  return (
    <div style={styles?.root}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Label>{label}</Label>
        {icon && <Icon iconName={icon} aria-label={icon} style={styles?.icon} />}
      </div>
      <ShowMore
        style={{ display: 'block', padding: '0 0 1em' }}
        content={content}
        maxLength={400}
      />
    </div>
  )
}
