import ConfirmDialog from '@components/ConfirmDialog'
import { MIMETYPE_PDF, RESOURCETYPES } from '@blaw/contracts-api-schema'

import { useTranslation } from '@hooks/useTranslation'

export type PdfConversionInfo = {
  resourceType: RESOURCETYPES | undefined
  documentId: string
  contractId: string
}

type SubmitPdfConversion = (
  documentId: string,
  contractId: string,
  mimeType: string,
  versionId?: string,
  resourceType?: RESOURCETYPES,
  editInWord?: boolean,
) => Promise<void | Window | null>

type Props = {
  pdfConversionHidden: boolean
  setPdfConversionHidden: React.Dispatch<React.SetStateAction<boolean>>
  pdfConversionInfo: PdfConversionInfo | undefined
  submitPdfConversion: SubmitPdfConversion
}

export default function PdfConversionForm({
  pdfConversionHidden,
  setPdfConversionHidden,
  pdfConversionInfo,
  submitPdfConversion,
}: Props) {
  const { t } = useTranslation()

  function onConfirm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    setPdfConversionHidden(true)
    if (pdfConversionInfo) {
      const { resourceType, documentId, contractId } = pdfConversionInfo
      submitPdfConversion(documentId, contractId, MIMETYPE_PDF, undefined, resourceType, true)
    }
  }

  return (
    <ConfirmDialog
      title={t('modal.Open PDF.Open PDF')}
      hidden={pdfConversionHidden}
      onDismiss={() => setPdfConversionHidden(true)}
      onConfirm={onConfirm}
      confirm={t('modal.Open PDF.Convert')}
    >
      {t('modal.Open PDF.Will Be Converted')}
    </ConfirmDialog>
  )
}
