import { useContext } from 'react'
import { ITextStyles } from '@fluentui/react'

import { OXContext } from '@contexts/OXContext'
import { OXFormProps } from '@modules/OXForm'
import { removeNonNumberValues } from '@modules/utils'
import StyledTextField from '@components/StyledTextField'
import CopyFromSelectionBtn from '@components/CopyFromSelectionBtn'
import { useTranslation } from '@hooks/useTranslation'
import { disabledTextFieldError } from '@modules/sharedStyles'

const styles: Partial<ITextStyles> = {
  root: { width: '100%' },
}

export default function CurrencyOXForm({ schemaKey, submitting }: OXFormProps) {
  const { copySelectionDisabled, formData, updateNumericOX, updateNotesOX, copyFromSelection } =
    useContext(OXContext)
  const { t } = useTranslation()
  const currencyOX = formData[schemaKey]
  const disabled = !currencyOX.included || submitting
  const disabledStyles = disabled ? disabledTextFieldError : {}

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <StyledTextField
          prefix="$"
          name="value"
          label={currencyOX.label}
          disabled={disabled}
          onChange={e => updateNumericOX(schemaKey, e.currentTarget.value)}
          required
          styles={{
            ...styles,
            ...disabledStyles,
          }}
          type="number"
          min="0"
          value={removeNonNumberValues(currencyOX.value)}
          errorMessage={currencyOX.errorMessage}
        />
      </div>
      <StyledTextField
        label={t('label.notes')}
        disabled={disabled}
        value={currencyOX.notes}
        onChange={e => updateNotesOX(schemaKey, e.currentTarget.value)}
        multiline
        styles={{ root: { marginTop: '1em' } }}
      />
      <CopyFromSelectionBtn
        onClick={() => copyFromSelection(schemaKey)}
        disabled={copySelectionDisabled || disabled}
      />
    </>
  )
}
