import { FC, MouseEvent, PropsWithChildren, useEffect, useState } from 'react'
import { Toggle } from '@fluentui/react'

import useAutosync, { restoreDocument, deleteDocument } from '@hooks/useAutosync'
import { HeaderNotificationSource } from '@components/HeaderNotification'
import AutoRecoverDialog from '@components/AutoRecoverDialog'
import AutoRecoverSuccess from '@components/AutoRecoverSuccess'
import { ADD_IN_HOST } from '@src/constants'

const AUTOSYNC_DEFAULT_STATE = true /* one place to change defaults */

const Footer: FC<PropsWithChildren> = () => {
  const [autoSync, setAutoSync] = useState<boolean>(false) /* start disabled */
  const [showRecovery, setShowRecovery] = useState<boolean>(false)
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const { documentId, loggedIn, remoteDateTime, checkingAutosyncStatus } = useAutosync({
    autoSync,
    setShowRecovery,
  })

  useEffect(() => {
    let desiredState = AUTOSYNC_DEFAULT_STATE
    if (!loggedIn) desiredState = false
    if (!documentId) desiredState = false
    if (checkingAutosyncStatus) desiredState = false
    if (showRecovery) desiredState = false
    if (ADD_IN_HOST.includes('local')) desiredState = false

    if (autoSync != desiredState) {
      setAutoSync(desiredState)
    }
  }, [documentId, loggedIn, checkingAutosyncStatus, showRecovery])

  function handleSyncFile() {
    setShowRecovery(false)
    restoreDocument(documentId).then(() => {
      setShowSuccess(true)
    })
  }

  function handleIgnoreFile() {
    setShowRecovery(false)
    void deleteDocument(documentId)
  }

  function onToggleChange(ev: MouseEvent<HTMLElement>, checked?: boolean) {
    setAutoSync(!!checked)
  }

  const toggleStyles = {
    root: {
      marginBottom: 0,
    },
    container: {
      marginLeft: 10,
    },
    label: {
      marginLeft: 5,
      fontWeight: 'normal',
    },
  }

  return (
    <>
      {loggedIn && showRecovery && (
        <HeaderNotificationSource>
          <AutoRecoverDialog
            onConfirm={() => handleSyncFile()}
            onDismiss={() => handleIgnoreFile()}
            lastModifiedDateTime={remoteDateTime}
          />
        </HeaderNotificationSource>
      )}
      {showSuccess && (
        <HeaderNotificationSource>
          <AutoRecoverSuccess onDismiss={() => setShowSuccess(false)} />
        </HeaderNotificationSource>
      )}
      <footer style={{ display: 'none' }}>
        <Toggle
          label="AutoSync"
          inlineLabel={true}
          disabled={!loggedIn || !documentId}
          checked={autoSync}
          onChange={onToggleChange}
          styles={toggleStyles}
        />
      </footer>
    </>
  )
}

export default Footer
