import { t } from 'i18next'

import { AccessControls, isPrivate, isPrivateButShared } from '@blaw/contracts-api-schema'
import { SharedColors } from '@fluentui/react'

export function accessDisplayData(access: AccessControls, enableV2: boolean) {
  let iconName = 'Unlock'
  let iconColor = SharedColors.green10
  let label = t('label.public-resource')

  if (isPrivateButShared(access) && enableV2) {
    iconColor = SharedColors.red10
    label = t('label.shared-resource')
  } else if (isPrivate(access)) {
    iconName = 'Lock'
    iconColor = SharedColors.red10
    label = t(enableV2 ? 'label.private' : 'label.private-resource')
  }

  return { iconName, iconColor, label }
}
