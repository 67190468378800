import ReactDOM from 'react-dom/client'
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons'
import { FluentProvider, webLightTheme } from '@fluentui/react-components'
import { HashRouter } from 'react-router-dom'
import { Buffer } from 'buffer'
window.Buffer = Buffer

import './styles.css'
import '@components/Icons'
import App from './App'

initializeIcons()
let isOfficeInitialized = false

function render(App: any) {
  // TODO: Fluent UI's List component in virtual mode fails in React Strict Mode
  // Strict Mode is temporarily disabled for now to ease development.
  // Known Issue: https://github.com/microsoft/fluentui/issues/29880
  ReactDOM.createRoot(document.getElementById('container') as HTMLElement).render(
    // <React.StrictMode>
    <HashRouter>
      <FluentProvider theme={webLightTheme}>
        <App isOfficeInitialized={isOfficeInitialized} />
      </FluentProvider>
    </HashRouter>,
    // </React.StrictMode>,
  )

  // @ts-ignore we added 'startupErrorHandler' on the window object to render error
  // in case our react app breaks
  clearTimeout(window.startupErrorHandler)
}

/* Render application after Office initializes */
Office?.onReady(function (info) {
  console.log('OfficeReady', { info })
  // info.host is null when add-in is opened in a browser
  if (!info.host) {
    window.location.href = 'https://pro.bloomberglaw.com/contract-solutions/'
  }
  isOfficeInitialized = true
  render(App)
  Office.addin.setStartupBehavior(Office.StartupBehavior.load)
  const { refreshAsync } = Office?.context?.document?.settings ?? {}
  if (!refreshAsync) return console.warn('No Office settings', Office?.context?.document?.settings)
  refreshAsync(function ({ status }) {
    if (status === Office.AsyncResultStatus.Succeeded) {
      Office.addin.getStartupBehavior().then((value: Office.StartupBehavior) => {
        if (value === Office.StartupBehavior.load) {
          Office.addin.showAsTaskpane()
        }
      })
    }
  })
})
