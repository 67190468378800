import { InstructionClauseIssue } from '@blaw/contracts-api-schema'
import LongTextWithLabel from '@components/LongTextWithLabel'

type Props = {
  issue: InstructionClauseIssue
}

export default function InstructionIssueContent(props: Props) {
  const { issue } = props

  return (
    <div style={{ padding: '0.5em 1em 2em 1.5em' }}>
      <LongTextWithLabel label={'Instruction'} content={issue.title} />
      <LongTextWithLabel label={'Contract Summary'} content={issue.contract_summary} />
      <LongTextWithLabel label={'Key Differences'} content={issue.differences} />
    </div>
  )
}
