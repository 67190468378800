import { useEffect, useState, MouseEvent, CSSProperties } from 'react'
import { SharedColors } from '@fluentui/react'

import UnstyledButton from '@baseComponents/UnstyledButton'

interface Props {
  content: string
  isTooLong?: (str: string, max: number) => boolean
  trim?: (str: string, max: number) => string
  renderer?: (str: string) => any
  maxLength?: number
  style?: CSSProperties
}

const ellipsis = <>&hellip;&nbsp;</>

function ShowMore({ content, maxLength = 143, style }: Props) {
  const tooLong = content.length > maxLength

  const [open, setOpen] = useState(!tooLong)
  const view = open ? content : content.slice(0, maxLength)
  const status = open ? 'Less' : 'More'

  const toggle = (e: MouseEvent<any>) => {
    setOpen(!open)
    e.preventDefault()
    return false
  }

  useEffect(() => {
    setOpen(!tooLong)
  }, [content, tooLong])

  return (
    <span style={{ margin: 0, ...style }}>
      {view}
      {open ? ' ' : ellipsis}
      {tooLong && (
        <UnstyledButton href="#" onClick={toggle} style={{ color: SharedColors.cyanBlue10 }}>
          Show&nbsp;{status}
        </UnstyledButton>
      )}
    </span>
  )
}

export default ShowMore
