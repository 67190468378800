import { useContext, useState } from 'react'
import {
  ActionButton,
  DocumentCard,
  DocumentCardTitle,
  Label,
  NeutralColors,
  Text,
} from '@fluentui/react'

import { type SystemMetadataKey, type UserMetadata } from '@blaw/contracts-api-schema'
import LoadingShimmer from '@components/LoadingShimmer'
import QuickMessage from '@components/QuickMessage'
import ShowMore from '@components/ShowMore'
import EditContractForm from '@components/EditContractForm'
import { ContractContext } from '@contexts/ContractContext'
import Contract from '@modules/Contract'
import { KeyTermsContext } from '@contexts/KeyTermsContext'
import { useTranslation } from '@hooks/useTranslation'
import DetailBadge from '@components/DetailBadge'
import { MetadataDetail } from '@modules/ContractDetail'
import ViewExtractedDataPointBtn from '@components/ViewExtractedDataPointBtn'
import {
  customFieldKeyToLabel,
  formatCustomFieldValue,
  sortedCustomFields,
} from '@modules/CustomFields'
import { ContractCustomFieldsData } from '@components/ContractCustomFields'

type MetadataKey = keyof UserMetadata | SystemMetadataKey
type GenInfoField = {
  name: keyof Contract
  key: keyof Contract | MetadataKey
  padding?: string
  width?: string
  component: typeof Detail | typeof FullWidthDetail | typeof CustomDetail
}

const styles = {
  padding: '0 1em 0 1em',
  width: '40%',
}
const GENERAL_INFORMATION_FIELDS: GenInfoField[] = [
  { name: 'description', key: 'description', component: FullWidthDetail, ...styles, width: '100%' },
  { name: 'type', key: 'contract_type', component: Detail, ...styles },
  { name: 'createdBy', key: 'contract_owner', component: Detail, ...styles },
  { name: 'dateCreated', key: 'entry_date', component: Detail, ...styles },
  { name: 'counterParty', key: 'party_name', component: Detail, ...styles },
  { name: 'clientName', key: 'client_name', component: Detail, ...styles },
  { name: 'category', key: 'contract_category', component: Detail, ...styles },
  { name: 'companyStakeholder', key: 'company_stakeholder', component: Detail, ...styles },
  { name: 'department', key: 'department', component: Detail, ...styles },
  { name: 'city', key: 'city', component: Detail, ...styles },
  { name: 'state', key: 'state', component: Detail, ...styles },
  { name: 'country', key: 'region', component: Detail, ...styles },
  { name: 'transaction', key: 'transaction', component: Detail, ...styles },
  { name: 'firstDraftOriginReadable', key: 'first_draft_origin', component: Detail, ...styles },
  { name: 'contractID', key: 'contract_id', component: Detail, ...styles },
  { name: 'customFields', key: 'customFields', component: CustomDetail },
]

interface Props {
  error?: string
  showEditBtn?: boolean
}

interface ShowMoreMetadataProps {
  isShowingMoreMetadata: boolean
  setIsShowingMoreMetadata: React.Dispatch<React.SetStateAction<boolean>>
  hidden: boolean
}

function ShowMoreMetadata({
  isShowingMoreMetadata,
  setIsShowingMoreMetadata,
  hidden,
}: ShowMoreMetadataProps) {
  const iconName = isShowingMoreMetadata ? 'chevronUp' : 'chevronDown'
  const label = isShowingMoreMetadata ? 'Show Less' : 'Show More'

  return (
    <ActionButton
      style={{
        display: hidden ? 'none' : 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        margin: '0.5em auto 0',
      }}
      iconProps={{ iconName }}
      onClick={() => setIsShowingMoreMetadata(!isShowingMoreMetadata)}
    >
      {label}
    </ActionButton>
  )
}

function GeneralInformation({ error, showEditBtn = true }: Props) {
  const { contract, loading } = useContext(ContractContext)
  const { loadingMetadataConfig, metadataConfig } = useContext(KeyTermsContext)
  const [hidden, setHidden] = useState(true)
  const [isShowingMoreMetadata, setIsShowingMoreMetadata] = useState(false)
  const { t } = useTranslation()

  if (error?.length) return <QuickMessage msg={error} type="error" style={{ margin: '0.7em' }} />
  if (loading || !contract) return <LoadingShimmer size={3} />

  const metadata = nonEmptyMetadataFields(contract)
  const showMoreBtnHidden =
    (contract.description?.length !== 0 && metadata.length < 6) ||
    (!contract.description?.length && metadata.length < 5)

  return (
    <DocumentCard
      aria-label={`${t('page.Contracts.General Information')} for ${contract.title}`}
      className="documentCard"
      style={{
        maxWidth: 'none',
        cursor: 'auto',
        border: `1px solid ${NeutralColors.gray60}`,
        userSelect: 'auto',
      }}
    >
      <DocumentCardTitle
        title={t('page.Contracts.General Information')}
        styles={{ root: { paddingLeft: '0.8em' } }}
      />

      <div style={{ overflow: 'hidden', marginBottom: '1em' }}>
        {renderMetadata(contract)}
        <ShowMoreMetadata
          isShowingMoreMetadata={isShowingMoreMetadata}
          setIsShowingMoreMetadata={setIsShowingMoreMetadata}
          hidden={showMoreBtnHidden}
        />
        <EditButton />
      </div>
      <EditContractForm hidden={hidden} setHidden={setHidden} />
    </DocumentCard>
  )

  function renderMetadata(contract: Contract) {
    if (isShowingMoreMetadata) return generateMetadataContent(metadata, contract)

    const maxDisplayableContent = metadata.find(field => field.name === 'description')
      ? metadata.slice(0, 5)
      : metadata.slice(0, 4)

    return generateMetadataContent(maxDisplayableContent, contract)
  }

  function nonEmptyMetadataFields(contract: Contract) {
    return GENERAL_INFORMATION_FIELDS.filter(({ name }) => {
      const n = name as keyof Contract
      const value = contract[n]
      if (!value) return false
      return (
        (!Array.isArray(value) && Object.keys(value).length !== 0) ||
        (Array.isArray(value) && value[0])
      )
    })
  }

  function generateMetadataContent(metadata: GenInfoField[], contract: Contract) {
    if (loadingMetadataConfig || !metadataConfig) return

    return (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {metadata.map((field, idx: number) => {
          const n = field.name as keyof Contract
          const text = contract[n] as string | string[]
          const key = field.key as keyof typeof contract.details
          const detail = contract.details[key] ?? ({} as MetadataDetail)

          return (
            <div key={idx} style={{ flexBasis: field.width, padding: field.padding }}>
              {field.component(idx, metadataConfig.getLabel(key), text, contract, detail)}
            </div>
          )
        })}
      </div>
    )
  }

  function EditButton() {
    if (!showEditBtn) return null

    return (
      <ActionButton
        iconProps={{ iconName: 'Edit' }}
        style={{
          float: 'right',
          marginTop: '1em',
          background: NeutralColors.gray20,
          height: '2em',
          marginRight: '1em',
        }}
        onClick={() => setHidden(false)}
      >
        Edit Information
      </ActionButton>
    )
  }
}

export default GeneralInformation

function Detail(
  idx: number,
  label: string,
  text: string | string[],
  contract?: Contract,
  detail?: MetadataDetail,
) {
  return (
    <div key={idx}>
      <Label>{label}</Label>
      {Array.isArray(text)
        ? text.map((item, idx) =>
            TextWithExtractedDataPoint(label, false, item, detail, contract, idx),
          )
        : TextWithExtractedDataPoint(label, false, text, detail, contract, idx)}
      <DetailBadge detail={detail} />
    </div>
  )
}

function FullWidthDetail(
  idx: number,
  label: string,
  text: string | string[],
  contract?: Contract,
  detail?: MetadataDetail,
) {
  return (
    <div key={idx}>
      <Label>{label}</Label>
      {Array.isArray(text)
        ? text.map((item, idx) =>
            TextWithExtractedDataPoint(label, true, item, detail, contract, idx),
          )
        : TextWithExtractedDataPoint(label, true, text, detail, contract, idx)}
      <DetailBadge detail={detail} />
    </div>
  )
}

function CustomDetail(
  idx: number,
  _l: any,
  customFields: ContractCustomFieldsData,
  _c: any,
  _d: any,
) {
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {sortedCustomFields(customFields).map(([key, value], i) => {
        return (
          <div key={idx + i + key} style={{ flexBasis: '40%', padding: '0 1em 0 1em' }}>
            <Label>{customFieldKeyToLabel(key)}</Label>
            <ShowMore content={formatCustomFieldValue(key, value?.toString())} maxLength={60} />
          </div>
        )
      })}
    </div>
  )
}

function TextWithExtractedDataPoint(
  label: string,
  isFullWidth: boolean,
  text: string,
  detail?: MetadataDetail,
  contract?: Contract,
  idx = 0,
) {
  return (
    <div key={idx} style={{ display: 'flex', alignItems: 'center' }}>
      {!isFullWidth ? <Text>{text}</Text> : <ShowMore content={text} maxLength={60} />}
      <ViewExtractedDataPointBtn label={label} idx={idx} detail={detail} contract={contract} />
    </div>
  )
}
