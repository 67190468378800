import { useEffect, useState } from 'react'
import { NeutralColors } from '@fluentui/theme'
import { Icon } from 'office-ui-fabric-react'
import { Checkbox, IContextualMenuItem } from '@fluentui/react'

import { DocumentVersion } from '@modules/DocumentVersion'
import CollapsibleItem from '@components/CollapsibleItem'
import useDocumentOpen from '@hooks/useDocumentOpen'
import DocumentOpenStatus from '@components/DocumentOpenStatus'
import TextBadge from '@components/TextBadge'
import useVersions from '@hooks/useVersions'
import DeleteActionBtn from '@components/DeleteActionBtn'
import UnstyledList from '@baseComponents/UnstyledList'
import { getFiletypeIconStyle } from '@modules/Contract'
import { friendlyDateTime } from '@modules/utils'
import { useTranslation } from '@hooks/useTranslation'
import ActionsMenu from '@components/ActionsMenu'
import { defaultActionsMenuItems } from '@src/data/defaultActionsMenuItems'
import PdfConversionForm, { PdfConversionInfo } from '@components/PdfConversionForm'
import { MIMETYPE_PDF } from '@blaw/contracts-api-schema'

const versionContentLabelStyles = {
  color: NeutralColors.gray130,
  margin: '0.6em 0 0.2em',
}

interface Props {
  contractId: string
  documentId: string
  versionId: string
  showDeleteBtn: boolean
}

const DocumentVersionsList = (props: Props) => {
  const { contractId, documentId, versionId, showDeleteBtn } = props
  const {
    versions,
    setDeleteModalHidden,
    setSelectedVersion,
    selectedForCompareOriginal,
    setSelectedForCompareOriginal,
    selectedForCompareModified,
    setSelectedForCompareModified,
    loadingCompare,
    loadingCompareError,
    setLoadingCompareError,
  } = useVersions(documentId)
  const { loadingDocument, openDocumentError, setOpenDocumentError, openDocumentHandler } =
    useDocumentOpen()
  const [versionItems, setVersionItems] = useState<DocumentVersion[]>([])
  const [pdfConversionHidden, setPdfConversionHidden] = useState(true)
  const [pdfConversionInfo, setPdfConversionInfo] = useState<PdfConversionInfo>()
  const { t } = useTranslation()

  useEffect(() => {
    if (versions.length) setVersionItems(mapDocumentVersionsToItems(versions))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [versions])

  const loadingMessage = loadingCompare ? 'Opening Compare...' : 'Opening Document...'

  return (
    <div>
      <UnstyledList>{renderDocumentVersionsList()}</UnstyledList>
      <DocumentOpenStatus
        loadingMessage={loadingMessage}
        loadingDocument={loadingDocument}
        openDocumentError={openDocumentError}
        setOpenDocumentError={setOpenDocumentError}
        clearErrorDelay={10000}
      />
      <PdfConversionForm
        pdfConversionHidden={pdfConversionHidden}
        setPdfConversionHidden={setPdfConversionHidden}
        pdfConversionInfo={pdfConversionInfo}
        submitPdfConversion={openDocumentHandler}
      />
      <DocumentOpenStatus
        loadingMessage={loadingMessage}
        loadingDocument={loadingCompare}
        openDocumentError={loadingCompareError}
        setOpenDocumentError={setLoadingCompareError}
        clearErrorDelay={10000}
      />
    </div>
  )

  function renderCompareCheckbox(version: DocumentVersion) {
    return (
      <Checkbox
        onChange={(_e, checked) => onCompareCheckboxSelection(_e, checked, version)}
        styles={{ checkbox: { margin: '1.3em 0.1em' } }}
        disabled={
          versions.length < 2 ||
          (selectedForCompareOriginal !== undefined &&
            selectedForCompareModified !== undefined &&
            version.version !== selectedForCompareOriginal.version &&
            version.version !== selectedForCompareModified.version)
        }
        checked={
          version.version === selectedForCompareOriginal?.version ||
          version.version === selectedForCompareModified?.version
        }
      />
    )
  }

  function renderDocumentVersionsList() {
    return versionItems?.map((version: DocumentVersion, idx: number) => {
      return (
        <CollapsibleItem
          key={idx}
          item={version}
          itemHeader={renderVersionHeader}
          itemHeaderButton={renderVersionHeaderButton}
          itemContent={version =>
            renderVersionContent(version, t('label.ContractMetadata.last-modified'))
          }
          itemCheckbox={renderCompareCheckbox}
        />
      )
    })
  }

  function mapDocumentVersionsToItems(documentVersions: DocumentVersion[]) {
    const items = documentVersions.map((documentVersion, idx) => {
      const item = { ...documentVersion }
      if (idx === 0) item.latest = true
      if (versions.length === 1) item.active = true
      if (versionId === item.version) {
        item.active = true
        item.viewing = true
      }
      item.versionNumber = documentVersions.length - idx
      return item
    })
    return items
  }

  function renderVersionHeader(version: DocumentVersion): JSX.Element {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Icon
            iconName={getFiletypeIconStyle(version.mimeType).iconName}
            style={{
              fontSize: '1.5em',
              color: getFiletypeIconStyle(version.mimeType).iconColor,
              margin: '0 0.5em 0 0',
            }}
          />
          <h4 style={{ margin: '0.5em 0' }}>Version {version.versionNumber}</h4>
          <span style={{ margin: '0 0.5em' }}>{version.latest ? '(Latest)' : ''}</span>
          {version.viewing ? <TextBadge>Viewing</TextBadge> : ''}
        </div>
      </div>
    )
  }

  function renderVersionHeaderButton(version: DocumentVersion) {
    return (
      <ActionsMenu
        items={defaultActionsMenuItems}
        onItemClick={(action: IContextualMenuItem) => onDocumentItemClick(version, action)}
      />
    )
  }

  async function onDocumentItemClick(version: any, action: IContextualMenuItem) {
    switch (action.key) {
      case 'viewInWeb':
        openDocumentHandler(documentId, contractId, version.mimeType, version.version)
        break
      case 'editInWord':
        if (version.mimeType === MIMETYPE_PDF) {
          setPdfConversionHidden(false)
          setPdfConversionInfo({
            resourceType: undefined,
            documentId: documentId,
            contractId: contractId,
          })
          return
        }
        openDocumentHandler(
          documentId,
          contractId,
          version.mimeType,
          version.version,
          undefined,
          true,
        )
        break
      default:
        throw new Error(`Invalid action "${action.key}"`)
    }
  }

  function onCompareCheckboxSelection(_e: any, checked: any, version: DocumentVersion) {
    if (checked) {
      // On selection
      if (!selectedForCompareOriginal && !selectedForCompareModified) {
        setSelectedForCompareOriginal(version)
      } else if (selectedForCompareOriginal && !selectedForCompareModified) {
        setSelectedForCompareModified(version)
      }
    } else {
      // On unselect
      if (version.version === selectedForCompareModified?.version) {
        setSelectedForCompareModified(undefined)
      } else if (version.version === selectedForCompareOriginal?.version) {
        setSelectedForCompareOriginal(selectedForCompareModified)
        setSelectedForCompareModified(undefined)
      }
    }
  }

  function renderVersionContent(version: DocumentVersion, modified: string): JSX.Element {
    const lastModified = friendlyDateTime(version.date)

    return (
      <div style={{ marginBottom: '1em' }}>
        <h4 style={versionContentLabelStyles}>{modified}</h4>
        <div>
          {lastModified} by {version.owner}
        </div>
        {version.description && (
          <>
            <h4 style={versionContentLabelStyles}>Description</h4>
            <div>{version.description}</div>
          </>
        )}
        {!version.latest && (
          <DeleteActionBtn
            onClick={() => {
              setDeleteModalHidden(false)
              setSelectedVersion(version)
            }}
            styles={{ root: { paddingLeft: '0', marginLeft: '-0.5em' } }}
            text="Delete Version"
            showBtn={showDeleteBtn}
          />
        )}
      </div>
    )
  }
}

export default DocumentVersionsList
