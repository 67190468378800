import { CSSProperties, PropsWithChildren } from 'react'

interface Props extends PropsWithChildren {
  style?: CSSProperties
}

const defaultStyle: CSSProperties = {
  border: '0.1em solid lightgray',
  padding: '1em',
  marginBottom: '0.4em',
}

const Box: React.FC<Props> = ({ style, children }: Props) => {
  return <div style={{ ...defaultStyle, ...style }}>{children}</div>
}

export default Box
