import { useNavigate } from 'react-router-dom'
import {
  Text,
  Stack,
  MessageBarType,
  ActionButton,
  SharedColors,
  IButtonStyles,
} from '@fluentui/react'

import { type AccessControls, isPrivate } from '@blaw/contracts-api-schema'
import Contract from '@modules/Contract'
import ConfirmDialog from '@components/ConfirmDialog'
import BoldText from '@baseComponents/BoldText'
import ErrorMessage from '@components/ErrorMessage'
import WarningMessage from '@components/ContractStatus/WarningMessage'
import { UserInfo } from '@modules/GetUsers'
import ResourceAccessLabel from '@components/ResourceAccessLabel'

const styleBtnRed = {
  backgroundColor: SharedColors.red10,
  borderColor: SharedColors.red10,
}

const denyBtnStyles: IButtonStyles = {
  root: { ...styleBtnRed },
  rootHovered: { ...styleBtnRed },
  rootPressed: { backgroundColor: SharedColors.red20, borderColor: SharedColors.red20 },
}

type Props = {
  users: UserInfo[]
  updating: boolean
  closed: boolean
  toggleHidden: () => void
  onAccessChange: (
    accessControls: AccessControls,
    resourceId: string,
    envelopeIds: string[],
  ) => Promise<void>
  error: string | null
  setError: React.Dispatch<React.SetStateAction<string | null>>
  loading: boolean
  contract?: Contract
  enableV2?: boolean
}

export default function ContractAccess({
  contract,
  users,
  updating,
  closed,
  toggleHidden,
  onAccessChange,
  error,
  setError,
  loading,
  enableV2 = false,
}: Props) {
  const navigate = useNavigate()

  if (!contract) return null

  const updateResourceAccessLabel = isPrivate(contract.accessControls)
    ? 'Grant Access'
    : 'Deny Access'
  const label = updating ? 'Updating...' : updateResourceAccessLabel

  const dismiss = () => {
    toggleHidden()
    setError(null)
  }

  const warningDisplay = () => {
    let message

    if (isPrivate(contract.accessControls)) {
      message =
        'Once you grant access to this contract, it will be visible to everyone in your organization.'
    } else {
      message =
        'Once you deny access to this contract, it will no longer be available to any user in your organization.'
    }

    return <WarningMessage message={message} type={MessageBarType.warning} />
  }

  const contractAccessContent = () => {
    return (
      <>
        {error && <ErrorMessage message={error} />}
        {warningDisplay()}
        <Stack style={{ justifyContent: 'space-between', margin: '1em 0' }}>
          <BoldText style={{ paddingBottom: '0.2em' }}>Contract Name</BoldText>
          <Text>{contract.title}</Text>
        </Stack>
      </>
    )
  }

  return (
    <Stack
      horizontal
      style={{
        alignItems: 'center',
        justifyContent: 'space-between',
        display: loading ? 'none' : 'flex',
      }}
    >
      <ResourceAccessLabel
        access={contract.accessControls}
        users={users}
        enableV2={enableV2}
        styles={{ icon: { padding: '0 0.5em 0 0' } }}
      />
      {renderButton()}

      <ConfirmDialog
        title={`${updateResourceAccessLabel} to Contract`}
        hidden={closed}
        disableBtn={updating}
        confirm={updateResourceAccessLabel}
        onConfirm={() =>
          onAccessChange(
            contract.accessControls,
            contract.id,
            contract.metadata.userMetadata.envelope_resource_id || [],
          )
        }
        onDismiss={dismiss}
        btnStyles={isPrivate(contract.accessControls) ? {} : denyBtnStyles}
      >
        {contractAccessContent() || ''}
      </ConfirmDialog>
    </Stack>
  )

  function renderButton() {
    if (enableV2)
      return (
        <ActionButton
          disabled={updating}
          onClick={() => navigate(`/contracts/${contract!.id}/resourceAccess`)}
          iconProps={{ iconName: 'Edit' }}
        >
          {isPrivate(contract?.accessControls || {}) ? 'Share' : 'Edit Access'}
        </ActionButton>
      )

    return (
      <ActionButton disabled={updating} onClick={toggleHidden} iconProps={{ iconName: 'Edit' }}>
        {label}
      </ActionButton>
    )
  }
}
