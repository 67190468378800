import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ActionButton, FontSizes, FontWeights, PrimaryButton, Stack } from '@fluentui/react'

import { useContractTaskPaneViewed } from '@modules/analytics'
import TopNav from '@components/TopNav'
import StyledStack from '@components/StyledStack'
import { ContractContext } from '@contexts/ContractContext'
import ResourceAccess from '@components/ResourceAccess'
import { ContractsContext } from '@contexts/ContractsContext'
import {
  AccessControls,
  createAccessObject,
  isPrivate,
  mapAccessObjectToUiAccess,
  ResourceAccessLevel,
  UiAccessObject,
} from '@blaw/contracts-api-schema'
import LoadingShimmer from '@components/LoadingShimmer'
import { StoreContext } from '@contexts/StoreContext'
import ContractTitle from '@baseComponents/ContractTitle'

export default function ResourceAccessControls() {
  const navigate = useNavigate()
  const { id } = useParams()
  const { sessionInfo } = useContext(StoreContext)
  const { users } = useContext(ContractsContext)
  const { contract, loading, loadContract, updateAccessV2, updateError } =
    useContext(ContractContext)
  const [submitting, setSubmitting] = useState(false)
  const [access, setAccess] = useState<UiAccessObject>({} as UiAccessObject)
  const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    if (!loading)
      setAccess(mapAccessObjectToUiAccess(contract?.accessControls || ({} as AccessControls)))
  }, [loading])

  useEffect(() => {
    if (Object.keys(access).length)
      setIsValid(
        JSON.stringify(access) !==
          JSON.stringify(
            mapAccessObjectToUiAccess(contract?.accessControls || ({} as AccessControls)),
          ),
      )
  }, [access])

  const isPrivateResource = isPrivate(contract?.accessControls || {})
  const pageTitle = isPrivateResource ? 'Share Contract' : 'Edit Contract Access'
  const buttonLabel = isPrivateResource ? 'Share' : 'Edit Access'
  const submitLabel = submitting ? 'Updating...' : buttonLabel

  useContractTaskPaneViewed({
    pageTitle,
    eventDetails: [`id: ${id}`],
  })

  if (!id) throw Error('Missing id param')

  const uiAccess = mapAccessObjectToUiAccess(contract?.accessControls || {})
  const usersWithDeleteAccess = Object.keys(uiAccess).filter(
    uuid => uiAccess.individualAccess[uuid] === ResourceAccessLevel.RW_DELETE,
  )

  return (
    <>
      <TopNav title={pageTitle} prevPath={`#/contracts/${id}`} />
      <StyledStack>
        <Stack.Item>{renderMain()}</Stack.Item>
      </StyledStack>
    </>
  )

  function renderMain() {
    if (loading || !(contract && id && Object.keys(access).length && users.length)) {
      return (
        <StyledStack>
          <LoadingShimmer />
        </StyledStack>
      )
    }

    return (
      <>
        <ContractTitle title={contract?.title} />
        <Stack tokens={{ childrenGap: '11' }}>
          <ResourceAccess
            access={access}
            users={users.filter(user => !usersWithDeleteAccess.includes(user.bbUuid.toString()))}
            uuid={sessionInfo.uuid || ''}
            updateAccess={setAccess}
            disabled={submitting}
            isV2Enabled={true}
          />
          <Stack.Item
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginTop: '1.5em',
            }}
          >
            <PrimaryButton onClick={submit} disabled={submitting || !isValid}>
              {submitLabel}
            </PrimaryButton>
            <ActionButton onClick={navBack}>Cancel</ActionButton>
          </Stack.Item>
        </Stack>
      </>
    )
  }

  function navBack() {
    id && navigate(`/contracts/${id}`)
  }

  async function submit() {
    if (!contract) return

    const { id, metadata } = contract
    setSubmitting(true)
    await updateAccessV2(
      createAccessObject(access),
      id,
      metadata.userMetadata.envelope_resource_id || [],
    )
    if (!updateError) {
      loadContract()
      navBack()
    }
    setSubmitting(false)
  }
}
