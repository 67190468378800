import { type IToggleProps, IToggleStyles, Toggle } from '@fluentui/react'

type ToggleProps = IToggleProps & {
  label: string | JSX.Element
}

const defaultToggleStyles: Partial<IToggleStyles> = {
  label: { marginLeft: '0.5em', cursor: 'pointer' },
}

export default function InlineToggle(props: ToggleProps) {
  return <Toggle inlineLabel styles={defaultToggleStyles} {...props} />
}
