import { useContext } from 'react'

import { OXContext } from '@contexts/OXContext'
import { OXFormProps } from '@modules/OXForm'
import { removeNonNumberValues } from '@modules/utils'
import StyledTextField from '@components/StyledTextField'
import CopyFromSelectionBtn from '@components/CopyFromSelectionBtn'
import { useTranslation } from '@hooks/useTranslation'
import { disabledTextFieldError } from '@modules/sharedStyles'

export default function DurationDaysOXForm({ schemaKey, submitting }: OXFormProps) {
  const { copySelectionDisabled, formData, updateNumericOX, updateNotesOX, copyFromSelection } =
    useContext(OXContext)
  const { t } = useTranslation()
  const numericOX = formData[schemaKey]
  const disabled = !numericOX.included || submitting

  return (
    <>
      <StyledTextField
        label={numericOX.label}
        disabled={disabled}
        name="number"
        suffix="days"
        onChange={e => updateNumericOX(schemaKey, e.currentTarget.value)}
        required
        type="number"
        min="0"
        value={removeNonNumberValues(numericOX.value)}
        errorMessage={numericOX.errorMessage}
        styles={disabled ? disabledTextFieldError : {}}
      />
      <StyledTextField
        label={t('label.notes')}
        disabled={disabled}
        value={numericOX.notes}
        onChange={e => updateNotesOX(schemaKey, e.currentTarget.value)}
        multiline
        styles={{ root: { marginTop: '1em' } }}
      />
      <CopyFromSelectionBtn
        onClick={() => copyFromSelection(schemaKey)}
        disabled={copySelectionDisabled || disabled}
      />
    </>
  )
}
