import { CSSProperties, FC, FormEvent, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  DefaultButton,
  FontSizes,
  FontWeights,
  Icon,
  MessageBar,
  MessageBarType,
  NeutralColors,
  PrimaryButton,
  ProgressIndicator,
  SharedColors,
  Toggle,
} from '@fluentui/react'

// Base Components
import ContractTitle from '@baseComponents/ContractTitle'
import UnstyledList from '@baseComponents/UnstyledList'
import UnstyledActionButton from '@baseComponents/UnstyledActionButton'

// Components
import ModalProgress from '@components/ModalProgress'
import StyledStack from '@components/StyledStack'
import TopNav from '@components/TopNav'
import DateOXForm from '@components/DateOXForm'
import TextOXForm from '@components/TextOXForm'
import BooleanOXForm from '@components/BooleanOXForm'
import ChoiceTextOXForm from '@components/ChoiceTextOXForm'
import CurrencyOXForm from '@components/CurrencyOXForm'
import DurationMonthsOXForm from '@components/DurationMonthsOXForm'
import DurationDaysOXForm from '@components/DurationDaysOXForm'
import ErrorMessage from '@components/ErrorMessage'
import LoadingShimmer from '@components/LoadingShimmer'

// Contexts
import { ContractContext } from '@contexts/ContractContext'
import { StoreContext } from '@contexts/StoreContext'
import { KeyTermsContext } from '@contexts/KeyTermsContext'
import { formDataType, OXContext } from '@contexts/OXContext'

// Hooks
import { useTranslation } from '@hooks/useTranslation'
import useContextualContractId from '@hooks/useContextualContractId'

// Modules
import ApiClient from '@modules/ApiClient'
import { useContractTaskPaneViewed } from '@modules/analytics'
import { OXFormProps } from '@modules/OXForm'
import Routes from '@modules/routes'
import { getDocParagraphsText, selectSectionInDocument } from '@modules/wordDocument'
// NOTE: Modules taken from KeyTermContext
import { KeyTerm } from '@modules/KeyTerm'
import { getContentHash } from '@modules/DocumentDefinitions'
import { MetadataConfig } from '@modules/MetadataConfig'

// Types/Interfaces
interface FormattedExtractedObligation extends Extraction {
  active: boolean
  key: string
}

type KeyTermDetailTemp = {
  system_extracted: boolean
}

type SessionStore = {
  requestId: string
  contentId: string
  lastHash: string
}

// Contracts API Schema
import {
  type ResourceVersionMetadataDetails,
  type TermsMetadataKey,
  Extraction,
  ExtractResponse,
  GetObligationsResponse,
  ParagraphText,
} from '@blaw/contracts-api-schema/'
import ContentCard from '@components/ContentCard'
import ShowMore from '@components/ShowMore'

// Constants
const OXTypeToFormComponent: {
  [key: string]: FC<OXFormProps>
} = {
  BOOLEAN: BooleanOXForm,
  CURRENCY: CurrencyOXForm,
  DATE: DateOXForm,
  DURATION_MONTHS: DurationMonthsOXForm,
  DURATION_DAYS: DurationDaysOXForm,
  TEXT: TextOXForm,
  CHOICE_TEXT: ChoiceTextOXForm,
}

const showMoreStyles: CSSProperties = {
  fontSize: '1.075em',
  marginRight: '0.5em',
}

// Fixtures
//import { mockObligations, mockGetObligationsResponse } from '@fixtures/extractedObligations'

const pageTitle = 'Attributes Extraction'
const apiClient = new ApiClient()
const routes = new Routes()

// ========================================
// Default function: AddObligationsUnsaved
// ========================================
export default function AddObligationsUnsaved() {
  // use the following for any shared static text
  const { t } = useTranslation()

  // --------------
  //  Use Contexts
  // --------------
  // use the following for any access related checks
  const { isOnDemandOxEnabled } = useContext(StoreContext)
  const {
    contract,
    title,
    loading: loadingContract,
    updateKeyTerms,
    loadContract,
  } = useContext(ContractContext)
  const { metadataConfig, loadingMetadataConfig } = useContext(KeyTermsContext)
  const { formValid, formData, setFormData, toggleObligation, handleFormValidation } =
    useContext(OXContext)

  const navigate = useNavigate()

  // ------------
  //  Use States
  // ------------
  const [obligations, setObligations] = useState([] as FormattedExtractedObligation[])
  const [submitting, setSubmitting] = useState(false)
  const [submitError, setSubmitError] = useState('')
  const [hideModalProgress, setHideModalProgress] = useState(true)
  const [message, setMessage] = useState({
    type: MessageBarType.info,
    message: '',
  })
  const [loadingObligations, setLoadingObligations] = useState(true)
  const [staleExtraction, setStaleExtraction] = useState(false)
  const [docHash, setDocHash] = useState('')

  const { DocumentSelectionChanged } = Office.EventType
  const { addHandlerAsync, removeHandlerAsync } = Office.context.document

  // ---------------------------
  // Saved or unsaved document?
  // ---------------------------
  const contractId: string = useContextualContractId() || ''

  // -----------------------------
  //  Backend interface functions
  // -----------------------------
  async function extractObligations(
    content: ParagraphText[],
    textHash: string,
  ): Promise<ExtractResponse> {
    console.debug(`Extract Attributes: textHash = ${textHash}`)

    const payload = {
      termTypes: ['all'],
      content: content,
      hash: textHash,
    }
    const { data } = await apiClient.post<ExtractResponse>(routes.extractObligationsUrl(), payload)
    const requestId = data.request_id
    const contentId = data.content_ids[0]

    console.debug(`Extract Attributes Response for ${textHash}: ${JSON.stringify(data, null, 2)}`)

    // Store the new requestId, contentId, and lastHash=currHash to localStorage
    const sessionStore: SessionStore = {
      requestId: requestId,
      contentId: contentId,
      lastHash: textHash,
    }
    sessionStorage.setItem('obligationExtracton', JSON.stringify(sessionStore))

    return data
  }

  async function getObligations(
    requestId: string,
    contentId: string,
    currHash: string,
  ): Promise<Extraction[]> {
    console.debug(`Get Obligations: requestId = ${requestId}; currentHash = ${currHash}`)

    let obligations: Extraction[] = []

    try {
      const { data } = await apiClient.get<GetObligationsResponse>(
        routes.getObligationsForUnsavedDocumentUrl(requestId, contentId, currHash),
      )

      if (data.obligations) {
        obligations = Object.entries(data.obligations).map(([_, obligation]) => obligation[0])
      }
    } catch (e) {
      setSubmitError(t('page.Extract Attributes.Unable To Add'))

      // The request id is stale. Try to re-extract.
      sessionStorage.removeItem('obligationExtracton')
    }

    return obligations
  }

  async function extractAndGetObligations(): Promise<Extraction[]> {
    // Get requestId, contentId, hash from localStorage
    const sessionStore: SessionStore = JSON.parse(
      sessionStorage.getItem('obligationExtracton') || '{}',
    )
    let requestId = sessionStore.requestId
    let contentId = sessionStore.contentId
    const lastHash = sessionStore.lastHash

    // Get document paragraph text (array of JSON objects with "text": <paragraph text> for each paragraph).
    const content = await getDocParagraphsText()
    // Calculate the hash of the current text
    const currHash = await getContentHash()

    console.debug(
      `(lastHash == currHash) = ${
        lastHash == currHash
      }; lastHash = ${lastHash}; currentHash = ${currHash}`,
    )

    // Missing odox data in local storage or document text has changed.
    if (!requestId || !contentId || !lastHash || lastHash !== currHash) {
      //console.debug(`Extract Attributes: requestId = ${requestId}; currentHash = ${currHash}`)

      const extractResponse = await extractObligations(content, currHash)
      requestId = extractResponse.request_id
      contentId = extractResponse.content_ids[0]
    }

    let obligations: Extraction[] = []
    try {
      obligations = await getObligations(requestId, contentId, currHash)
    } catch (e) {
      // Request id and/or content id might be stale. So, lets re-extract
      const extractResponse = await extractObligations(content, currHash)
      requestId = extractResponse.request_id
      contentId = extractResponse.content_ids[0]

      obligations = await getObligations(requestId, contentId, currHash)
    }

    setDocHash(currHash)
    setStaleExtraction(false)

    return obligations
    // return mockGetObligationsResponse as GetObligationsResponse
  }

  function getFormData(
    formattedAndFilteredObligations: FormattedExtractedObligation[],
    keyTerms?: KeyTerm[],
  ): formDataType {
    return formattedAndFilteredObligations.reduce((acc, { key, value, schemaLabel, data_type }) => {
      let validatedValue = value
      let isValid = data_type !== 'UNSTRUCTURED'
      let errorMessage = isValid ? '' : 'Field is required'
      let keyTerm = {} as KeyTerm

      if (keyTerms) {
        keyTerm = keyTerms.find(keyTerm => keyTerm.key === key) ?? {}
        if (Object.keys(keyTerm).length) {
          validatedValue = keyTerm.value
          isValid = true
          errorMessage = ''
        }
      }

      if (!isValid) {
        console.error(`Value type mismatch for ${key}`)
      }

      acc[key] = {
        label: schemaLabel,
        value: validatedValue,
        included: true,
        isValid: isValid,
        errorMessage: errorMessage,
        items: keyTerm.items ?? [],
        notes: keyTerm.data?.notes ?? '',
      }
      return acc
    }, {} as formDataType)
  }

  async function showObligations(metadataConfig: MetadataConfig) {
    setLoadingObligations(true)
    extractAndGetObligations()
      .then(obligations => {
        console.debug('Extracted Attributes:', obligations)

        const formattedAndFilteredObligations = formatAndFilterObligations(obligations)
        console.debug('Formatted and Filtered Attributes:', formattedAndFilteredObligations)

        const keyTerms = contract && contract.existingKeyTerms(metadataConfig.value)
        const initialFormData = getFormData(formattedAndFilteredObligations, keyTerms)

        setFormData(initialFormData)
        handleFormValidation(initialFormData)
        setObligations(formattedAndFilteredObligations)

        !formattedAndFilteredObligations.length &&
          setMessage({
            type: MessageBarType.info,
            message: t('page.Extract Attributes.No Extracted Obligations'),
          })
      })
      .finally(() => {
        setLoadingObligations(false)
      })
  }

  async function checkSelection() {
    const newHash = await getContentHash()
    const isStale = docHash !== newHash
    console.debug(`Document is stale: ${isStale}`)
    setStaleExtraction(isStale)
  }

  // -------
  //  Hooks
  // -------
  useEffect(() => {
    addHandlerAsync(DocumentSelectionChanged, checkSelection)
    return () => {
      removeHandlerAsync(DocumentSelectionChanged, { handler: checkSelection })
    }
  }, [docHash, loadingObligations])

  useEffect(() => {
    console.debug('Extracting and Getting obligations')

    if (contractId) {
      console.debug(`Saved Contract! Loading contract id: ${contractId}`)
      setMessage({
        type: MessageBarType.info,
        message: t('page.Extract Attributes.Select Extracted Terms'),
      })
    } else {
      console.debug('Unsaved Contract!')
      setMessage({
        type: MessageBarType.warning,
        message:
          t('label.contract-not-added') +
          t('page.Extract Attributes.Saving New Contract With Extracted Terms'),
      })
    }

    if (
      loadingMetadataConfig ||
      !metadataConfig ||
      !metadataConfig?.value.length ||
      (contractId && loadingContract) // contract is saved and its still loading
    ) {
      console.debug('metadataConfig is NOT available')
      return
    }

    showObligations(metadataConfig)
  }, [loadingContract, loadingMetadataConfig, metadataConfig])

  // ---------------------------------------------------------------------------------
  useContractTaskPaneViewed({ pageTitle })

  if (!isOnDemandOxEnabled)
    return <ErrorMessage message={t('page.Extract Attributes.Service Unavailable')} />

  return (
    <>
      <TopNav title={pageTitle} showAIGeneratedBadge />
      <MessageBar messageBarType={message.type}>{message.message}</MessageBar>
      <StyledStack>{renderMain()}</StyledStack>
    </>
  )

  // ---------------------
  //  Rendering functions
  // ---------------------
  function renderStaleExtraction() {
    return (
      <div style={{ display: staleExtraction ? '' : 'none', marginBottom: '0.2em' }}>
        <MessageBar messageBarType={MessageBarType.warning}>
          {t('page.Extract Attributes.Stale Extraction')}
        </MessageBar>
        <DefaultButton
          style={{ marginTop: '0.5em', width: '100%' }}
          onClick={() => metadataConfig && showObligations(metadataConfig)}
        >
          {t('page.Extract Attributes.Extract Again')}
        </DefaultButton>
      </div>
    )
  }

  function renderSavedDocument() {
    return (
      <>
        <form onSubmit={onSubmit}>
          <ContractTitle title={title} />
          <UnstyledList>{obligations.map(renderObligation)}</UnstyledList>
          <PrimaryButton
            type="submit"
            disabled={!formValid || submitting}
            style={{ width: '100%' }}
          >
            {submitting
              ? t('page.Extract Attributes.Adding Selected')
              : t('page.Extract Attributes.Add Selected')}
          </PrimaryButton>
        </form>
        <ModalProgress
          title={submitError ? 'An error occurred' : 'Please wait'}
          hidden={hideModalProgress}
          text={t('page.Extract Attributes.Adding Selected')}
          error={submitError}
          cancelBtn={submitError ? 'Close' : false}
          onDismiss={() => {
            setSubmitError('')
            setHideModalProgress(true)
          }}
        />
      </>
    )
  }

  function renderUnsavedDocument() {
    return (
      <>
        <form onSubmit={onSubmit}>
          <UnstyledList>{obligations.map(renderObligation)}</UnstyledList>
          <PrimaryButton
            type="submit"
            disabled={!formValid || submitting}
            style={{ width: '100%' }}
          >
            {t('button.Save As New Contract')}
          </PrimaryButton>
        </form>
      </>
    )
  }

  function renderMain() {
    if (loadingMetadataConfig || loadingObligations || (contractId && loadingContract)) {
      return (
        <>
          <ProgressIndicator label={t('page.Extract Attributes.Extracting')} />
          <LoadingShimmer />
        </>
      )
    }

    return (
      <>
        {renderStaleExtraction()}
        {contractId ? renderSavedDocument() : renderUnsavedDocument()}
      </>
    )
  }

  function renderObligation(obligation: FormattedExtractedObligation) {
    const FormComponent = OXTypeToFormComponent[obligation.schemaType]
    const disabled = !formData[obligation.key].included

    const contentCardStyles: CSSProperties = { padding: '0.7em 0.7em 0 0.7em' }
    const contentTitleStyles: CSSProperties = { fontSize: FontSizes.large }

    return (
      <li
        key={obligation.key}
        style={{ display: 'flex', flexDirection: 'column', marginBottom: '0.5em' }}
      >
        <ContentCard
          style={contentCardStyles}
          titleStyle={contentTitleStyles}
          bottomRight={searchInDocument(obligation, disabled)}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Toggle
              styles={{ root: { marginRight: '0.5em', marginBottom: '0' } }}
              checked={!disabled}
              onChange={() => toggleObligation(obligation.schemaKey)}
              disabled={submitting}
              data-testid={`obligation_toggle_${obligation.schemaKey}`}
            />
            <span
              style={{ fontWeight: FontWeights.semibold, fontSize: '1.15em', marginRight: '0.5em' }}
            >
              {obligation.schemaLabel}
            </span>
          </div>
          <FormComponent schemaKey={obligation.schemaKey} submitting={submitting} />
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '0.5em' }}>
            {unstructuredMessage(obligation, disabled)}
          </div>
        </ContentCard>
      </li>
    )
  }

  async function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    try {
      setSubmitting(true)
      setHideModalProgress(false)

      const keyTerms = Object.fromEntries(
        Object.entries(formData)
          .filter(([_, entry]) => entry.included)
          .map(([key, entry]) => {
            const { value, notes } = entry
            return [key, { value, data: { notes, block: '', text: '' } }]
          }),
      )

      console.debug(`Submit: KeyTerms = ${JSON.stringify(keyTerms)}`)

      if (contractId) {
        // For Saved Documents
        const allTerms = JSON.parse(
          JSON.stringify({
            ...contract?.customMetadata.terms,
            ...keyTerms,
          }),
        )
        const contractMetadata = JSON.parse(JSON.stringify(contract?.metadata))
        contractMetadata.systemMetadata = {}
        contractMetadata.userMetadata = {}
        contractMetadata.customMetadata.terms = allTerms
        await updateKeyTerms(contractMetadata, e)
        setHideModalProgress(true)

        loadContract()

        contract?.id && navigate(`/contracts/${contract.id}/keyTerms`)
      } else {
        // For Unsaved Documents
        setHideModalProgress(true)
        navigate('/contracts/new', {
          state: {
            terms: keyTerms,
          },
        })
      }
    } catch (e) {
      setSubmitError(t('page.Extract Attributes.Unable To Add'))
    } finally {
      setSubmitting(false)
    }
  }

  function displayObligation(
    obligation: FormattedExtractedObligation,
    keyTermDetail: KeyTermDetailTemp,
  ) {
    // Exclude obligation if it has already been manually entered on key terms page (only possible for Saved Documents)
    if (Object.keys(keyTermDetail).length && !keyTermDetail?.system_extracted) {
      console.debug(`[displayObligation] ${obligation.schemaKey} was already manually entered`)
      return false
    }

    // Exclude obligation if no schemaKey info
    if (!Object.hasOwn(obligation, 'schemaKey')) {
      console.warn(
        `[displayObligation] ${obligation.code} is not listed in Contracts API Schema. Excluded from obligations presented to the user.`,
      )
      return false
    }

    return true
  }

  function formatAndFilterObligations(obligations: Extraction[]): FormattedExtractedObligation[] {
    // Format Obligations
    const formattedObligations = obligations.map(obligation => {
      return {
        ...obligation,
        key: obligation.schemaKey,
        active: false,
      }
    })

    // Filter Obligations
    if (contract) {
      // Saved Documents have manually entere / system extracted obligations
      const keyTermsDetails: ResourceVersionMetadataDetails = contract.details
      console.debug(
        'Saved Contract manually entered / system extracted obligations',
        keyTermsDetails,
      )

      return formattedObligations.filter(obligation => {
        const keyTermDetail =
          keyTermsDetails[obligation.schemaKey as TermsMetadataKey] || ({} as KeyTermDetailTemp)
        return displayObligation(obligation, keyTermDetail)
      })
    } else {
      // For Unsaved Documents
      return formattedObligations.filter(obligation => {
        return displayObligation(obligation, {} as KeyTermDetailTemp)
      })
    }
  }

  function unstructuredMessage(obligation: FormattedExtractedObligation, disabled: boolean) {
    // TODO: Might have to check raw_value is not empty if raw_value ever comes back empty
    if (obligation.data_type !== 'UNSTRUCTURED') return <></>

    let unstructuredMessage = 'Value type mismatch'
    if (obligation.schemaType === 'DATE') {
      unstructuredMessage = 'Unrecognized date format'
    }

    return (
      <li style={{ display: 'flex', flexDirection: 'column', marginBottom: '0.25em' }}>
        <div style={{ display: 'flex' }}>
          <Icon
            iconName="error"
            style={
              disabled
                ? { color: NeutralColors.gray50, padding: '0.5em 0em 0em 0em' }
                : { color: SharedColors.red20, padding: '0.5em 0em 0em 0em' }
            }
          />
          <small
            style={
              disabled
                ? { color: NeutralColors.gray50, padding: '0.40em' }
                : { color: SharedColors.red20, padding: '0.40em' }
            }
          >
            {unstructuredMessage}
          </small>
        </div>
        <ShowMore
          content={obligation.raw_value}
          maxLength={142}
          style={disabled ? { ...showMoreStyles, color: NeutralColors.gray90 } : showMoreStyles}
        />
      </li>
    )
  }

  function searchInDocument(obligation: FormattedExtractedObligation, disabled: boolean) {
    return (
      <UnstyledActionButton
        disabled={disabled}
        iconProps={{ iconName: 'DocumentSearch' }}
        onClick={() => {
          selectSectionInDocument(obligation.block_id ? obligation.block_id[0] : 0)
        }}
        title="View in Document"
      >
        View in Document
      </UnstyledActionButton>
    )
  }
}
