import { DialogType, ProgressIndicator, Text } from '@fluentui/react'

import { deleteBtnStyles } from '@modules/sharedStyles'
import ConfirmDialog from '@components/ConfirmDialog'
import ErrorMessage from '@components/ErrorMessage'
import QuickMessage from '@components/QuickMessage'

type Props = {
  hidden: boolean
  error: string
  onDismiss: () => void
  text?: string
  title?: string
  description?: string
  cancelBtn?: string | boolean
  warn?: string
}

export default function ModalProgress(props: Props) {
  const type = props.error ? DialogType.close : DialogType.largeHeader
  return (
    <ConfirmDialog
      title={props.title}
      hidden={props.hidden}
      onDismiss={props.onDismiss}
      cancelBtn={props.cancelBtn}
      btnStyles={deleteBtnStyles}
      dialogContentProps={{ type }}
    >
      <QuickMessage msg={props.warn} show={!!props.warn} type="warning" />
      <Progress {...props} />
      <ErrorMessage message={props.error} />
    </ConfirmDialog>
  )
}

function Progress({ text, description, error }: Props) {
  if (error) return null
  return (
    <>
      {text && <Text style={{ display: 'block', marginTop: '1em' }}>{text}</Text>}
      <ProgressIndicator description={<>{description}</>} />
    </>
  )
}
