import { Redline, redlineToOOXML } from './Redline'
import { withTimeout } from './utils'

function getDocument(sliceSize: number): Promise<Office.AsyncResult<Office.File>> {
  return new Promise((resolve, reject) => {
    Office.context.document.getFileAsync(Office.FileType.Compressed, { sliceSize }, r => {
      if (r.status === Office.AsyncResultStatus.Succeeded) {
        resolve(r)
      } else {
        reject(r)
      }
    })
  })
}

function getSlice(file: Office.File, sliceNum: number): Promise<Office.Slice> {
  return new Promise((resolve, reject) => {
    file.getSliceAsync(sliceNum, async result => {
      if (result.status !== Office.AsyncResultStatus.Succeeded) {
        reject(result)
      }
      resolve(result.value)
    })
  })
}

async function* iterSlices(file: Office.File) {
  for (let sliceNum = 0; sliceNum < file.sliceCount; ++sliceNum) {
    yield await getSlice(file, sliceNum)
  }
}

export async function* iterDocSlices(sliceSize: number) {
  let document
  try {
    document = await withTimeout<Office.AsyncResult<Office.File>>(
      30000,
      'getDocument',
      getDocument(sliceSize),
    )
    for await (const slice of iterSlices(document.value)) {
      yield slice
    }
  } catch (e) {
    console.error(e)
  } finally {
    document?.value.closeAsync()
  }
}

export async function getDocIndexedParagraphsForXMLInput() {
  return Word.run(async context => {
    const paragraphs = context.document.body.paragraphs
    paragraphs.load('items')
    await context.sync()
    let paragraphStr = ''
    for (const idx in paragraphs.items) {
      paragraphStr += `<paragraph idx=${idx}>${paragraphs.items[idx].text}</paragraph>`
    }
    return paragraphStr
  })
}

export async function getDocBodyText() {
  return Word.run(async context => {
    const { body } = context.document
    body.load('text')
    await context.sync()
    return body.text
  })
}

export async function getDocParagraphsText() {
  return Word.run(async context => {
    const { paragraphs } = context.document.body
    paragraphs.load('items')
    await context.sync()
    const items = paragraphs.items
    return items.map(item => ({ text: item.text }))
  })
}

export async function selectSectionInDocument(paragraphIndex: number) {
  await Word.run(async context => {
    const { paragraphs } = context.document.body
    paragraphs.load('items')
    await context.sync()
    paragraphs.items[paragraphIndex].select(Word.SelectionMode.select)
    await context.sync()
  })
}

export async function modifyCurrentSelection(func: (arg: Word.Range) => Promise<any>) {
  await Word.run(async context => {
    const selection = context.document.getSelection()
    await func(selection)
    await context.sync()
  })
}

export async function insertText(text: string | undefined) {
  if (!text) return
  await modifyCurrentSelection(async sel => sel.insertText(text, Word.InsertLocation.replace))
}

export async function insertDiff(markup: Redline) {
  await modifyCurrentSelection(async sel => {
    sel.load(['font', 'style', 'styleBuiltin'])
    await sel.context.sync()
    const xml = redlineToOOXML(markup, sel)
    sel.insertOoxml(xml, Word.InsertLocation.replace)
  })
}
