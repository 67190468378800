import { FontSizes, IButtonProps, IconButton, SharedColors, Stack } from '@fluentui/react'
import { CSSProperties, useId } from 'react'

export type SmallButtonProps = IButtonProps & {
  iconName?: string
  variant?: 'primary' | 'default' | 'destructive'
  btnSize?: 'small' | 'medium' | 'large'
  style?: CSSProperties
}

const variantColors = {
  primary: SharedColors.cyanBlue10,
  default: 'inherit',
  destructive: SharedColors.red20,
}
const variantSizes = {
  small: FontSizes.small,
  medium: FontSizes.medium,
  large: FontSizes.large,
}

export default function SmallButton({
  iconName,
  children,
  onClick,
  style,
  disabled,
  variant = 'default',
  btnSize = 'small',
  ...btnProps
}: SmallButtonProps) {
  const color = disabled ? SharedColors.gray10 : variantColors[variant]
  const fontSize = variantSizes[btnSize]
  const id = useId()

  return (
    <Stack horizontal style={{ alignItems: 'center' }}>
      <IconButton
        id={id}
        iconProps={{
          iconName,
          style: { margin: 0, color },
          styles: { root: { fontSize } },
        }}
        disabled={disabled}
        onClick={onClick}
        style={{
          fontSize,
          opacity: disabled ? 0.5 : 1,
          ...style,
        }}
        {...btnProps}
      />
      <label htmlFor={id}>{children}</label>
    </Stack>
  )
}
