import {
  createContext,
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
  useContext,
} from 'react'
import { type AxiosResponse } from 'axios'
import { t } from 'i18next'

import {
  FacetResult,
  PlaybookResource,
  SearchResult,
  searchKeyWordArgs,
  constraintsType,
  RESOURCETYPES_OBJ,
  SearchResults,
} from '@blaw/contracts-api-schema'
import usePagination, { PaginationComponent, PaginationMeta } from '@hooks/usePagination'
import ApiClient from '@modules/ApiClient'
import { StoreContext } from '@contexts/StoreContext'
import { addToArray, delay, removeFromArray } from '@modules/utils'
import { KeyTermsContext } from './KeyTermsContext'
import { FacetLabel, FacetListItem, FilterState } from '@modules/ClauseAnalyzer'
import { useDebounce } from '@hooks/useDebounce'
import { searchInputDelay } from '@modules/defaults'
import { DEFAULT_NUM_ITEMS_PER_PAGE, getPaginationMeta } from '@modules/Pagination'

interface Props {
  children?: any
  fetchingItemsDefault?: boolean
}
export interface PlaybooksContextState {
  query: string
  setQuery: Dispatch<SetStateAction<string>>
  error?: string
  setError: Dispatch<SetStateAction<string | undefined>>
  warn: string
  Pagination: PaginationComponent
  pageNum: number
  topOfPageRef: MutableRefObject<HTMLDivElement | null>
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  deleteModalHidden: boolean
  setDeleteModalHidden: Dispatch<SetStateAction<boolean>>
  deleting: boolean
  playbooks: PlaybookResource[]
  items: PlaybooksResponse
  facetLabels: FacetLabel
  setPlaybooks: Dispatch<SetStateAction<PlaybookResource[]>>
  fetchPlaybooks: (query: string, page: number, filters: FilterState) => Promise<PlaybooksResult>
  loadPage: (query: string) => void
  loadNextPage: (page: number) => void
  loadFirstPage: (...args: any) => Promise<void>
  fetchingItems: MutableRefObject<boolean>
  deleteSelectedPlaybook: (id: string) => Promise<void>
  cancelFilters: () => void
  filterState: FilterState
  handleFiltering: (facetName: string, facetItem: string, checked: boolean) => void
  numFilters: () => number
  sortOrder: string[]
  filterType: string
  title: string
  path: string
}

type PlaybooksResult = {
  playbooks: PlaybookResource[]
  meta: PaginationMeta
  items: {
    facets: FacetResult[]
    count: number
  }
  warn?: string
  error?: string
}

export const reqFacetNames = [
  'contract_type',
  'first_draft_origin',
  'playbook_party_role',
  'playbook_status',
]
const DELETE_PLAYBOOK_DELAY = 3000

const sortOrder = [
  'filterOwner',
  'docType',
  'filterStatus',
  'filterIndustry',
  'filterGoverningLaw',
  'filterParty',
  'filterLawFirm',
  'filterYear',
]

const filterType = 'Playbook'
const title = 'Filter Playbooks'
const path = '/playbooks'
const playbooksPerPage = 20

type PlaybooksResponse = {
  facets: FacetListItem[]
  count: number
}

const playbooksLabel = t('page.Playbooks.plural')
const playbookLabel = t('page.Playbooks.singular')
export const NO_PLAYBOOKS_EXISTS =
  `No ${playbooksLabel} have been created yet. Click Add above to create your first ${playbookLabel}` as const

export const PlaybooksContext = createContext({} as PlaybooksContextState)

function PlaybooksContextProvider(props: Props) {
  const [query, setQuery] = useState('')
  const [error, setError] = useState<string>()
  const [warn, setWarn] = useState('')
  const [loading, setLoading] = useState(true)
  const [deleteModalHidden, setDeleteModalHidden] = useState(true)
  const [deleting, setDeleting] = useState(false)
  const [filterState, setFilterState] = useState<FilterState>({})
  const [prevFilterState, setPrevFilterState] = useState<FilterState>({})
  const [playbooks, setPlaybooks] = useState<PlaybookResource[]>([])
  const [, setMeta] = useState<PaginationMeta>()
  const [facets, setFacets] = useState<FacetListItem[]>([])
  const [count, setCount] = useState<number>(0)
  const [facetLabels, setFacetLabels] = useState<FacetLabel>({})
  const { metadataConfig, loadingMetadataConfig } = useContext(KeyTermsContext)
  const {
    storeSessionInfo,
    routes: { searchUrl, resourcesUrl, servicesApiUrl },
  } = useContext(StoreContext)
  const fetchingItems = useRef(props.fetchingItemsDefault ?? false)
  const apiClient = new ApiClient(storeSessionInfo, setError)

  const { loadFirstPage, Pagination, pageNum } = usePagination({
    loadPage,
    loadNextPage,
    hitsPerPage: playbooksPerPage,
    scrollToTop: () => topOfPageRef.current && topOfPageRef.current.scrollIntoView(),
  })

  const debouncedLoad = useDebounce(loadFirstPage, searchInputDelay, [])
  const debouncedSetFilter = useDebounce(setFilterState, searchInputDelay, [])

  useEffect(() => {
    if (JSON.stringify(filterState) !== JSON.stringify(prevFilterState)) {
      loadFirstPage(query)
      setPrevFilterState({ ...filterState })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState])

  useEffect(() => {
    debouncedSetFilter({})
    debouncedLoad(query)
  }, [query])

  useEffect(() => {
    if (!loadingMetadataConfig && metadataConfig) {
      setFacetLabels({
        contract_type: metadataConfig.getLabel('contract_type'),
        first_draft_origin: metadataConfig.getLabel('first_draft_origin'),
        playbook_party_role: metadataConfig.getLabel('playbook_party_role'),
        playbook_status: metadataConfig.getLabel('playbook_status'),
      })
    }
  }, [loadingMetadataConfig, metadataConfig])

  const topOfPageRef = useRef<null | HTMLDivElement>(null)

  async function fetchPlaybooks(query: string, page: number, filters: FilterState) {
    const result: PlaybooksResult = {
      playbooks: [],
      meta: { totalPages: 0, totalHits: 0 },
      items: { facets: [], count: 0 },
    }
    filters = Object.fromEntries(Object.entries(filters).filter(([_, v]) => v.length))
    const params: searchKeyWordArgs = {
      userQuery: query,
      systemQuery: [],
      facetMaxValuesPerFacet: 1000,
      facetSortOrder: 'COUNT_DESCENDING',
      context: RESOURCETYPES_OBJ.MY_PLAYBOOKS,
      sortField: { fieldName: query ? 'relevance' : 'last_updated_date', Direction: 'desc' },
      maxDigests: 0,
      facetsResultType: 'BY_NAME',
      offset: page * DEFAULT_NUM_ITEMS_PER_PAGE,
      maxResults: DEFAULT_NUM_ITEMS_PER_PAGE,
      facets: Object.entries(filters).map(
        entry => ({ key: entry[0], value: entry[1] } as constraintsType),
      ),
      ranges: [],
      fetchFields: [],
      facetsNames: reqFacetNames,
      constraints: [],
    }
    try {
      const {
        data: { results, facets, totalHits },
      } = (await apiClient.post(searchUrl, params)) as AxiosResponse<SearchResults>

      const meta = getPaginationMeta(totalHits)

      if (results?.length) {
        const playbooks = results.map(searchResultToResource)
        setPlaybooks(playbooks)
        setMeta(meta)
        const newFacets = facets.map(
          ({ name, values, type }) =>
            ({
              field: name,
              values: values.map(({ label, value, count }) => ({ label, value, count, type })),
            } as FacetListItem),
        )
        setFacets(newFacets)
        setCount(totalHits)
        result.playbooks = playbooks
        result.meta = meta
      } else {
        setCount(0)
        result.warn = query.trim() ? t('page.Contracts.No Results Warning') : NO_PLAYBOOKS_EXISTS
      }
    } catch (e) {
      console.error(e)
      result.error = (e as Error).message
    }
    return result
  }

  function searchResultToResource(searchResult: SearchResult): PlaybookResource {
    const { metadata } = searchResult
    return {
      id: searchResult.documentId,
      name: searchResult.documentName,
      resourceType: RESOURCETYPES_OBJ.MY_PLAYBOOKS,
      createdBy: searchResult.modifiedBy || metadata.owner?.value || '',
      dateModified: new Date(metadata.last_updated_date?.value || ''),
      dateCreated: new Date(searchResult.dateCreated),
      metadata: {
        userMetadata: {
          contract_type: metadata.contract_type?.value ?? '',
          description: metadata.description?.value ?? '',
          first_draft_origin: metadata.first_draft_origin?.value ?? '',
          playbook_template_ids: metadata.playbook_template_ids?.value ?? [],
          playbook_contract_ids: metadata.playbook_contract_ids?.value ?? [],
          customer_instructions: metadata.customer_instructions?.value ?? [],
          extracted_instructions: metadata.extracted_instructions?.value ?? [],
          playbook_party_role: metadata.playbook_party_role?.value ?? '',
          playbook_party_roles: metadata.playbook_party_roles?.value ?? [],
          playbook_status: metadata.playbook_status?.value ?? 'DRAFT',
        },
        systemMetadata: {
          entry_date: '',
          last_updated_date: '',
        },
        customMetadata: { digests: searchResult.digests },
      },
    }
  }

  async function loadPage(query: string) {
    setError('')
    setWarn('')
    setLoading(true)
    fetchingItems.current = true

    const { playbooks, meta, error, warn } = await fetchPlaybooks(query, 0, filterState)

    fetchingItems.current = false
    setLoading(false)
    if (warn) setWarn(warn)
    if (error) setError(error)
    if (!playbooks.length) return {}

    return meta
  }

  async function loadNextPage(page: number) {
    setError('')
    setWarn('')
    const { playbooks: newPlaybooks, warn, error } = await fetchPlaybooks(query, page, filterState)

    if (warn) setWarn(warn)
    if (error) setError(error)
    setPlaybooks([...playbooks, ...newPlaybooks])
    return
  }

  async function deleteSelectedPlaybook(id: string) {
    if (!id) return
    setDeleting(true)
    const deleteUrl = `${resourcesUrl}/${id}`
    const deleteIndexingUrl = `${servicesApiUrl}/delete-index?ids[]=${id}`

    try {
      setLoading(true)
      setError('')

      await apiClient.delete(deleteUrl)
      await apiClient.get(deleteIndexingUrl)
      await delay(DELETE_PLAYBOOK_DELAY)

      await loadPage('')
      setDeleteModalHidden(true)
    } catch (e) {
      console.error(e)
      setError('Failed to delete playbook.')
      setTimeout(() => {
        setError('Delete timeout. Please try again.')
      }, 10000)
    } finally {
      setDeleting(false)
      setLoading(false)
    }
  }

  function handleFiltering(facetName: string, facetItem: string, checked: boolean) {
    setFilterState(prevState => {
      let previousFacetName = prevState[facetName]
      if (!previousFacetName) {
        previousFacetName = []
      }
      return {
        ...prevState,
        [facetName]: checked
          ? addToArray(previousFacetName, facetItem)
          : removeFromArray(previousFacetName, facetItem),
      }
    })
  }

  function numFilters() {
    return Object.values(filterState).reduce(
      (prevValue, filterArray) => prevValue + filterArray.length,
      0,
    )
  }

  function cancelFilters() {
    setFilterState({})
  }

  const value = {
    query,
    setQuery,
    error,
    setError,
    warn,
    Pagination,
    pageNum,
    topOfPageRef,
    loading,
    setLoading,
    deleteModalHidden,
    setDeleteModalHidden,
    deleting,
    playbooks,
    setPlaybooks,
    items: { facets, count },
    fetchPlaybooks,
    loadPage,
    loadNextPage,
    loadFirstPage,
    fetchingItems,
    deleteSelectedPlaybook,
    facetLabels,
    cancelFilters,
    filterState,
    handleFiltering,
    numFilters,
    sortOrder,
    filterType,
    title,
    path,
  }

  return <PlaybooksContext.Provider value={value}>{props.children}</PlaybooksContext.Provider>
}

export default PlaybooksContextProvider
