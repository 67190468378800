import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { Contract } from '@blaw/contracts-api-schema'
import { FacetLabel, FacetListItem, FilterState, ItemAndCount } from '@modules/ClauseAnalyzer'
import { clauseSourceLabels } from '@contexts/SimilarClausesContext'

export default function useSortedFacetGroups(
  facets: FacetListItem[],
  facetLabels: FacetLabel,
  filterState: FilterState,
  sortOrder: string[],
) {
  const [sortedFacets, setSortedFacets] = useState<FacetListItem[]>([])

  useEffect(() => {
    cleanAndSortFacets(facets, facetLabels, filterState, sortOrder, setSortedFacets)
  }, [facets])

  return sortedFacets
}

function cleanAndSortFacets(
  facets: FacetListItem[],
  facetLabels: FacetLabel,
  filterState: FilterState,
  sortOrder: string[],
  setSortedFacets: Dispatch<SetStateAction<FacetListItem[]>>,
) {
  if (!facets) return
  const filtered = facets.filter((facetGroup: FacetListItem) => {
    const facetCategoryName = facetGroup.field
    const facetShouldDisplay = !!facetLabels[facetCategoryName]
    if (facetShouldDisplay) {
      facetGroup.values = reSortGroup(enhanceGroup(facetGroup), filterState)
    }
    return facetShouldDisplay
  })

  const sorted = filtered.sort((a: FacetListItem, b: FacetListItem) => {
    return sortOrder.indexOf(a.field) - sortOrder.indexOf(b.field)
  })
  setSortedFacets(sorted)
}

function enhanceGroup(facetGroup: FacetListItem) {
  const { field, values } = facetGroup
  if (field === 'filterStatus') {
    for (const item of values) {
      const statusIdx = item.value || 'DRAFT'
      item.label = Contract.contractStatus.getLabel(statusIdx)
    }
  } else if (field === 'clauseSource') {
    for (const item of values) {
      const clauseSourceIdx = item.value as keyof typeof clauseSourceLabels
      item.label = clauseSourceLabels[clauseSourceIdx]
    }
  }
  return facetGroup
}

function reSortGroup({ field, values }: FacetListItem, filterState: FilterState) {
  const selectedItems: ItemAndCount[] = []
  const nonSelectedItems = values.filter(facetItem => {
    const isSelected = filterState[field]?.includes(facetItem.value)
    if (isSelected) selectedItems.push(facetItem)
    return !isSelected
  })
  filterState[field]?.forEach((filter: string) => {
    const found = selectedItems.find(item => item.value === filter)
    if (!found) {
      const filterToAdd = {
        value: filter,
        count: 0,
      }
      selectedItems.push(filterToAdd)
    }
  })
  return [...selectedItems, ...nonSelectedItems]
}
