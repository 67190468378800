import { useContext, useEffect, useRef, useState } from 'react'

import Routes from '@modules/routes'
import ApiClient from '@modules/ApiClient'
import { hash } from '@modules/utils'
import { GetWordDocumentContent } from '@modules/GetWordDocumentContent'
import { StoreContext } from '@contexts/StoreContext'
import { useDataInjection } from '@hooks/useDataInjection'
import useFunctionBlocker from '@hooks/useFunctionBlocker'

const AUTOSYNC_TIMEOUT = 30000

type Props = {
  autoSync: boolean
  setShowRecovery: (arg: boolean) => void
}

export default function useAutosync({ autoSync, setShowRecovery }: Props) {
  const [checkingAutosyncStatus, setCheckingAutosyncStatus] = useState(true)
  const [remoteSha, setRemoteSha] = useState<string>('')
  const [remoteDateTime, setRemoteDateTime] = useState<string>('')
  const [documentId, setDocumentId] = useState<string>('') /* start disabled */
  const { loggedIn } = useContext(StoreContext)
  const onIntervalCallback = useRef<any>()
  const { documentId: injectedDocumentId } = useDataInjection()
  const { blockableFunction } = useFunctionBlocker()

  function initAutosync(): boolean | undefined {
    // TODO check how this is going to work for new document that is uploaded to contracts management
    // @ts-ignore injectedDocumentId was previously typed as string but that was a mistake,
    // it's not guaranteed to exist.
    setDocumentId(injectedDocumentId)

    if (!loggedIn) return true
    if (!injectedDocumentId) return true
    if (!autoSync) return true

    getDetails(injectedDocumentId).then(async details => {
      const localSha = await getDocSha256()

      let { lastSaveTime: localDateTime } = await getDocProperties()
      localDateTime = localDateTime || new Date()

      const [
        {
          sha256: remoteSha = localSha,
          lastAutoSaveTime: remoteDateTime = localDateTime.toString(),
        } = {},
      ] = details.autoSave
      setRemoteDateTime(remoteDateTime.toString())

      if (remoteSha && localSha !== remoteSha) {
        const localMillis = localDateTime.getTime()
        const remoteMillis = Date.parse(remoteDateTime)

        if (remoteMillis > localMillis) {
          setShowRecovery(true)
        }
      }
      setCheckingAutosyncStatus(false)
    })
  }

  async function saveDocument(localSha: string) {
    const res = await sendDocument(documentId, localSha)
    if (!res) return
    setRemoteSha(res.sha256)
  }

  useEffect(() => {
    onIntervalCallback.current = async () => {
      const localSha = await getDocSha256()
      if (localSha !== '' && localSha !== remoteSha) {
        await blockableFunction('Autosync', () => saveDocument(localSha))
      }
    }
  })

  useEffect(() => {
    if (!autoSync) return
    const timerId = setInterval(() => onIntervalCallback.current(), AUTOSYNC_TIMEOUT)
    console.log('After setInterval...', timerId)
    return () => {
      clearInterval(timerId)
      console.log('After clearInterval...', timerId)
    }
  }, [autoSync])

  useEffect(() => {
    const isInitFinished = initAutosync()
    if (isInitFinished) {
      setCheckingAutosyncStatus(false)
    }
  }, [])

  return {
    documentId,
    loggedIn,
    remoteDateTime,
    checkingAutosyncStatus,
  }
}

const routes = new Routes()
const apiClient = new ApiClient()

export type PostAutosyncResponse = {
  documentId: string
  sha256: string
  lastAutoSaveTime: string
}

type GetAutosyncResponseEntry = {
  sha256: string
  lastAutoSaveTime: string
}

export type GetAutosyncResponse = {
  autoSave: GetAutosyncResponseEntry[]
}

export async function sendDocument(
  documentId: string,
  contentSha256: string,
): Promise<PostAutosyncResponse | undefined> {
  async function saveRemotely(buf: Blob): Promise<PostAutosyncResponse> {
    const formData = new FormData()
    formData.append('file', buf, `${documentId}.docx`)
    formData.append('documentId', documentId)
    formData.append('contentSha256', contentSha256)
    formData.append('lastAutoSaveTime', new Date().toISOString())

    const headers = {
      'Content-Type': 'multipart/form-data',
    }

    const { data } = await apiClient.post<PostAutosyncResponse>(routes.autosyncUrl, formData, {
      headers,
    })
    return data
  }

  try {
    const docdata = await GetWordDocumentContent()
    return await saveRemotely(docdata)
  } catch (error) {
    console.error(error)
  }
}

export async function getDetails(documentId: string): Promise<GetAutosyncResponse> {
  try {
    const { data } = await apiClient.get<GetAutosyncResponse>(
      routes.getAutosyncDetailsUrl(documentId),
    )
    return data
  } catch (error) {
    console.error(error)
    return {
      autoSave: [],
    }
  }
}

export async function restoreDocument(documentId: string): Promise<void> {
  const { data } = await apiClient.get<string>(routes.getAutosyncContentUrl(documentId))

  await Word.run(async context => {
    const body = context.document.body
    body.insertFileFromBase64(data, Word.InsertLocation.replace)
    await context.sync()
  })
}

export async function deleteDocument(documentId: string): Promise<void> {
  try {
    await apiClient.delete(routes.getAutosyncDetailsUrl(documentId))
  } catch (error) {
    console.error(error)
  }
}

export function getDocSha256(): Promise<string> {
  return Word.run(async context => {
    const { body } = context.document
    body.load('text')
    await context.sync()
    return await hash(body.text)
  })
}

export async function getDocProperties(): Promise<Word.Interfaces.DocumentPropertiesData> {
  return Word.run(async context => {
    const builtInProperties = context.document.properties
    builtInProperties.load('*')
    await context.sync()
    return builtInProperties.toJSON()
  })
}
