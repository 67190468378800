import { useState, useContext } from 'react'
import {
  Label,
  mergeStyles,
  MessageBar,
  NeutralColors,
  Pivot,
  PivotItem,
  PrimaryButton,
  Stack,
  TextField,
  Text,
  MessageBarType,
} from '@fluentui/react'

import StyledStack from '@components/StyledStack'
import TopNav from '@components/TopNav'
import CollapsibleItem from '@components/CollapsibleItem'
import LinkButton from '@components/LinkButton'
import LoadingShimmer from '@components/LoadingShimmer'
import ApiClient from '@modules/ApiClient'
import Routes from '@modules/routes'
import { navigateToExternalSite } from '@modules/utils'
import { navClicked, searchSubmitted, useContractTaskPaneViewed } from '@modules/analytics'
import { SemanticSearchResponse } from '@blaw/contracts-api-schema'
import UnstyledList from '@baseComponents/UnstyledList'
import { useTranslation } from '@hooks/useTranslation'
import guidance from '@data/guidance'
import { StoreContext } from '@contexts/StoreContext'

mergeStyles({
  ':global(.subTopic)': {
    paddingBottom: '0.6em',
  },
  ':global(.subTopic:last-child)': {
    paddingBottom: 0,
  },
})

interface GuidanceLink {
  link: string
  label: string
  name: string
  links: GuidanceLink[]
}

interface GuidanceTopic {
  name: string
  active: boolean
  links: GuidanceLink[]
  key: string
}

const pageTitle = 'Practical Guidance'
const apiClient = new ApiClient()
const routes = new Routes()

export default function PracticalGuidance() {
  const { t } = useTranslation()
  const [filteredTopics, setFilteredTopics] = useState(initialTopics)
  const [activeTab, setActiveTab] = useState('0')
  const [query, setQuery] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [searchResult, setSearchResult] = useState<SemanticSearchResponse>({})
  const [error, setError] = useState('')
  const isQueryEmpty = !query.replaceAll(/\n/g, '').trim().length
  const { isSemanticSearchEnabled } = useContext(StoreContext)

  useContractTaskPaneViewed({ pageTitle })

  return (
    <div>
      <TopNav title={pageTitle} showAIGeneratedBadge={activeTab === '1'} />
      {isSemanticSearchEnabled ? renderTabs() : <></>}
      {isSemanticSearchEnabled ? renderContent() : renderFirstTabContent()}
    </div>
  )

  function renderTabs() {
    return (
      <Pivot
        onLinkClick={item => {
          setQuery('')
          setFilteredTopics(initialTopics)
          setSearchResult({})
          setActiveTab(item?.props.itemKey ?? '0')
        }}
        styles={{
          root: { display: 'flex', justifyContent: 'space-around' },
          link: { flexGrow: 1 },
        }}
        selectedKey={activeTab}
      >
        <PivotItem headerText="Search Links" itemKey="0" />
        <PivotItem headerText="Search BLAW" itemKey="1" />
      </Pivot>
    )
  }

  function renderFirstTabContent() {
    return (
      <>
        <MessageBar>
          <span dangerouslySetInnerHTML={{ __html: t('page.Practical Guidance.Need Help') }} />
        </MessageBar>
        <StyledStack style={{ paddingTop: '0.3em' }}>
          <Stack.Item grow={1}>
            <Label>{t('page.Practical Guidance.Search Links')}</Label>
          </Stack.Item>
          <Stack.Item grow={1} style={{ marginBottom: '0.5em' }}>
            <TextField
              value={query}
              aria-label="Search Links"
              placeholder={t('page.Practical Guidance.Enter Keywords')}
              iconProps={{ iconName: 'Search' }}
              autoComplete="off"
              onChange={(_, value) => {
                setQuery(value ?? '')
                handleLinksSearch(value ?? '')
                searchSubmitted({
                  isLoggedIn: true,
                  pageTitle,
                  searchScope: pageTitle,
                  searchAction: 'initial',
                  searchTool: 'filter',
                  searchTermsExist: true,
                  searchType: 'feature search',
                  keywords: value,
                })
              }}
              autoFocus
            />
          </Stack.Item>
          <Stack.Item style={{ padding: 0 }}>
            <UnstyledList>
              {filteredTopics.map(topic => {
                return (
                  <CollapsibleItem
                    key={topic.name + topic.links.length + topic.active}
                    item={topic}
                    itemHeader={topic => topic.name}
                    itemContent={renderTopicContent}
                  />
                )
              })}
            </UnstyledList>
          </Stack.Item>
        </StyledStack>
      </>
    )
  }

  function renderContent() {
    switch (activeTab) {
      case '0':
        return renderFirstTabContent()
      case '1':
        return (
          <>
            {error && <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>}
            <StyledStack style={{ paddingTop: '0.3em' }}>
              <Stack.Item grow={1}>
                <Label>{t('page.Practical Guidance.Search BLAW')}</Label>
              </Stack.Item>
              <Stack.Item style={{ marginBottom: '0.5em' }}>
                <Stack horizontal style={{ gap: '0.5em' }}>
                  <TextField
                    title="Search BLAW"
                    value={query}
                    disabled={submitting}
                    placeholder={t('page.Practical Guidance.Enter Keywords')}
                    autoComplete="off"
                    onKeyDown={e => e.key === 'Enter' && !isQueryEmpty && handleBLAWSearch(query)}
                    onChange={(_, value) => setQuery(value ?? '')}
                    autoFocus
                    styles={{ root: { flexGrow: 1 } }}
                  />
                  <PrimaryButton
                    aria-label="Submit Search"
                    disabled={submitting || isQueryEmpty}
                    onClick={() => handleBLAWSearch(query)}
                    iconProps={{ iconName: 'Search' }}
                    styles={{ root: { flexGrow: 0.1, padding: 0, minWidth: 0, height: 'auto' } }}
                  />
                </Stack>
              </Stack.Item>
              <Stack.Item>{renderSearchResult()}</Stack.Item>
            </StyledStack>
          </>
        )
    }
  }

  function renderLink({ label, link }: GuidanceLink) {
    return (
      <LinkButton
        key={link}
        iconName="OpenInNewTab"
        onClick={() => {
          navigateToExternalSite(link)
          navClicked({
            isLoggedIn: true,
            pageTitle,
            navGroup: '',
            navItem: label,
            navType: 'Right Bar',
          })
        }}
        buttonStyles={{
          root: {
            margin: '0 0.3em',
            padding: '0.5em 0.3em',
            textAlign: 'left',
            width: '100%',
            display: 'block',
          },
          rootHovered: { backgroundColor: NeutralColors.gray20 },
        }}
      >
        {label}
      </LinkButton>
    )
  }

  function renderSubTopic(subtopicCategory: string, links: GuidanceLink[]) {
    return (
      <div
        className="subTopic"
        key={subtopicCategory}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <Label>{subtopicCategory}</Label>
        <UnstyledList>{links.map(link => renderLink(link))}</UnstyledList>
      </div>
    )
  }

  function renderTopicContent(topic: GuidanceTopic) {
    return (
      <UnstyledList style={{ display: 'flex', flexDirection: 'column', marginBottom: '0.6em' }}>
        {topic.links.map(link =>
          link.links ? renderSubTopic(link.name, link.links) : renderLink(link),
        )}
      </UnstyledList>
    )
  }

  function initialTopics(): GuidanceTopic[] {
    const deepCopy = JSON.parse(JSON.stringify(guidance)) as GuidanceTopic[]
    return deepCopy.map(topic => ({ ...topic, active: false }))
  }

  /*
  If the topic has no subtopics:
  - Show all matching labels under the topic
  - If there are no matching labels but the topic matches, show all labels under the topic

  If the topic has subtopics:
  - Show all matching labels
  - If there are no matching labels but the subtopic matches, show the subtopic and all labels under the subtopic (e.g. "securities" for Corporate Transactions)
  - If there are no matching labels or subtopics but the topic matches, show all subtopics and their labels (e.g. "corporate" for Corporate Transactions)
  */

  function filterTopics(query: string, topicName: string, links: GuidanceLink[]) {
    const matchTopicName = topicName.toLowerCase().includes(query)
    const filteredTopicLinks = links.filter(link => {
      if (link.label) {
        return link.label.toLowerCase().includes(query)
      } else {
        const filteredSubTopicLinks = link.links.filter(link =>
          link.label.toLowerCase().includes(query),
        )
        const matchOnlySubTopicName =
          link.name.toLowerCase().includes(query) && !filteredSubTopicLinks.length
        link.links =
          matchOnlySubTopicName || (!filteredSubTopicLinks.length && matchTopicName)
            ? link.links
            : filteredSubTopicLinks
        return link.links.length
      }
    })
    const matchOnlyTopicName = matchTopicName && !filteredTopicLinks.length
    const filteredResult = matchOnlyTopicName ? links : filteredTopicLinks
    return filteredResult
  }

  function handleLinksSearch(query: string) {
    const formattedQuery = query.toLowerCase().trim()

    if (!formattedQuery.length) {
      setFilteredTopics(initialTopics())
      return
    }
    const filtered = initialTopics().filter(topic => {
      topic.links = filterTopics(formattedQuery, topic.name, topic.links)
      const matches = topic.links.length
      if (matches) topic.active = true
      return matches
    })
    setFilteredTopics(filtered)
  }

  function renderSearchResult() {
    if (submitting) return <LoadingShimmer />
    if (!searchResult.summary) return
    return (
      <>
        <div style={{ marginBottom: '1em' }}>
          <Label>{t('page.Practical Guidance.Bloomberg Law Answer')}</Label>
          <Text>{searchResult.summary}</Text>
        </div>
        <div>
          <Label>{t('page.Practical Guidance.Sources')}</Label>
          <UnstyledList>
            {searchResult.answers?.map((answer, index) => (
              <li
                key={index}
                style={{
                  background: NeutralColors.gray20,
                  marginBottom: '0.6em',
                  padding: '0.5em',
                  borderRadius: '4px',
                }}
              >
                <div style={{ display: 'flex' }}>
                  <div style={{ whiteSpace: 'nowrap' }}>{index + 1}.</div>
                  <div style={{ margin: '0 0 0.5em 0.5em' }}>{answer.docTitle}</div>
                </div>
                <div style={{ textAlign: 'right' }}>
                  <LinkButton
                    onClick={() =>
                      navigateToExternalSite(
                        `https://www.bloomberglaw.com/product/BLAW/document/${answer.docId}`,
                      )
                    }
                    iconName="OpenInNewTab"
                  >
                    {t('button.View Full Document')}
                  </LinkButton>
                </div>
              </li>
            ))}
          </UnstyledList>
        </div>
      </>
    )
  }

  async function handleBLAWSearch(query: string) {
    try {
      setError('')
      setSubmitting(true)
      // TODO: verify
      searchSubmitted({
        isLoggedIn: true,
        pageTitle,
        searchScope: pageTitle,
        searchAction: 'initial',
        searchTool: 'filter',
        searchTermsExist: true,
        searchType: 'feature search',
        keywords: query,
      })
      const { data } = await apiClient.post<SemanticSearchResponse>(routes.semanticSearchUrl, {
        query,
      })
      setSearchResult(data)
    } catch (e) {
      console.error(e)
      setError('An error occured')
    } finally {
      setSubmitting(false)
    }
  }
}
