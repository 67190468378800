import { CSSProperties, useState } from 'react'
import { Dropdown, IDropdownOption, ResponsiveMode } from '@fluentui/react'

import DeleteIcon from '@baseComponents/DeleteIcon'
import { ResourceAccessLevel } from '@blaw/contracts-api-schema'

type Props = {
  defaultSelectedKey: number
  label: string
  handleChange: (uuid: string, value: number) => void
  removeValue?: (i: string, e: never[]) => void
  uuid?: string
  userRole?: string
  index?: string
  disabled?: boolean
  style?: CSSProperties
}

const AccessLevelLabel = {
  0: 'No Access',
  1: 'Read Only',
  2: 'Write',
  3: 'Delete',
}

const BusinessUserOptions = [
  {
    key: ResourceAccessLevel.NO_ACCESS,
    text: AccessLevelLabel[ResourceAccessLevel.NO_ACCESS],
  },
  {
    key: ResourceAccessLevel.READ_ONLY,
    text: AccessLevelLabel[ResourceAccessLevel.READ_ONLY],
  },
]

const LawyerOptions = [
  ...BusinessUserOptions,
  {
    key: ResourceAccessLevel.READ_WRITE,
    text: AccessLevelLabel[ResourceAccessLevel.READ_WRITE],
  },
]

export default function AccessField(props: Props) {
  const {
    defaultSelectedKey,
    label,
    handleChange,
    removeValue,
    uuid = '',
    userRole,
    index,
    disabled,
    style,
  } = props
  const options = userRole === 'lawyer' ? LawyerOptions : BusinessUserOptions
  const [selectedItem, setSelectedItem] = useState<IDropdownOption>(options[defaultSelectedKey])

  const onChange = (
    _event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    _index?: number,
  ): void => {
    if (option) {
      setSelectedItem(option)
      handleChange(uuid, option.key as number)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginTop: '1em',
        ...style,
      }}
    >
      <span
        style={{
          flexGrow: 1,
        }}
      >
        {label}
      </span>
      <Dropdown
        selectedKey={selectedItem?.key}
        options={options}
        onChange={onChange}
        responsiveMode={ResponsiveMode.unknown}
        dropdownWidth="auto"
        disabled={disabled}
        style={{ marginLeft: '0.3em' }}
      />
      {removeValue && (
        <DeleteIcon disabled={disabled} onClick={() => (index ? removeValue(index, []) : null)} />
      )}
    </div>
  )
}
