import { Depths, FontWeights } from '@fluentui/react'
import styled from 'styled-components'

export interface StatusBadgeProps {
  children?: React.ReactNode
  status: string
  badgeColor: string
  style?: any
}

const StyledSpan = styled.span<StatusBadgeProps>`
  display: block;
  color: white;
  padding: 0.25em 0.5em;
  width: fit-content;
  border-radius: 4px;
  font-size: 0.8em;
  box-shadow: ${Depths.depth8};
  background-color: ${props => props.badgeColor};
  border: 1px solid ${props => props.badgeColor};
  font-weight: ${FontWeights.semibold};
`

export const StatusBadge: React.FC<StatusBadgeProps> = (props: StatusBadgeProps) => {
  return <StyledSpan {...props}>{props.status}</StyledSpan>
}
