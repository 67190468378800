import { titleize } from '@modules/utils'
import ApiClient from './ApiClient'
import Routes from './routes'

// TODO: return type from contracts-api, need to sync with schema
export interface UserInfo {
  firstName: string
  lastName: string
  email: string
  bbUuid: number
  bbFirmId: number
  bbCustomerId: number
  role: string
}

export type AuthorizorRole = keyof typeof authorizerToAppRoleMapping

export const authorizerToAppRoleMapping = {
  admin: 'admin',
  business: 'business user',
  lawyer: 'legal user',
}

const routes = new Routes()

// TODO: similar function from contracts-api, need to sync with schema
export function getFullName(user: UserInfo): string {
  const { firstName, lastName } = user
  return titleize(`${firstName} ${lastName}`)
}

export async function getUsers(): Promise<UserInfo[]> {
  const apiClient = new ApiClient()

  const { data } = await apiClient.get<UserInfo[]>(routes.usersUrl)
  return data
}
