import { CSSProperties, PropsWithChildren } from 'react'

import Box from '@baseComponents/Box'
import { FontWeights } from '@fluentui/react'

type Props = PropsWithChildren & {
  title?: string
  topRight?: JSX.Element
  bottomRight?: JSX.Element
  style?: CSSProperties
  titleStyle?: CSSProperties
  headingLevel?: 1 | 2 | 3 | 4 | 5 | 6
}

const defaultTitleStyle: CSSProperties = {
  margin: 0,
  fontWeight: FontWeights.semibold,
}

export default function ContentCard({
  title,
  children,
  topRight,
  bottomRight,
  style,
  titleStyle,
  headingLevel = 2,
}: Props) {
  const Heading = `h${headingLevel}` as keyof JSX.IntrinsicElements

  return (
    <Box style={style}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
        <Heading style={{ ...defaultTitleStyle, ...titleStyle }}>{title}</Heading>
        {topRight}
      </div>
      <section style={{ padding: '0.7em 0' }}>{children}</section>
      <div style={{ textAlign: 'right' }}>{bottomRight}</div>
    </Box>
  )
}
