import { t } from 'i18next'
import {
  ChoiceGroup,
  IChoiceGroupOption,
  IMessageBarStyles,
  Label,
  MessageBar,
  MessageBarType,
  Stack,
} from '@fluentui/react'

import {
  AccessControlLevel,
  createAccessObject,
  getDefaultAccess,
  isPrivate,
  mapAccessObjectToUiAccess,
  ResourceAccessLevel,
  UiAccessObject,
} from '@blaw/contracts-api-schema'
import MultiCombobox from '@components/MultiCombobox'
import {
  authorizerToAppRoleMapping,
  AuthorizorRole,
  getFullName,
  UserInfo,
} from '@modules/GetUsers'
import AccessField from '@components/AccessField'
import ContentCard from '@components/ContentCard'

interface Props {
  access: UiAccessObject
  users: UserInfo[]
  updateAccess: (access: UiAccessObject) => void
  isV2Enabled: boolean
  disabled: boolean
  uuid?: string
  boxWrap?: boolean
}

const privateStyles: IMessageBarStyles = {
  root: { width: '100%', marginLeft: '1em' },
  innerText: { whiteSpace: 'wrap' },
}
const publicStyles: IMessageBarStyles = {
  root: { width: '100%', margin: '-1em 0em -1em 1em' },
  innerText: { whiteSpace: 'wrap' },
}
const accessLevels = [
  {
    key: AccessControlLevel.PRIVATE,
    text: t('label.private'),
    help: t('helpText.private'),
    type: MessageBarType.info,
    style: {
      root: { display: 'flex', alignItems: 'center', minHeight: '32px' },
      help: privateStyles,
    },
  },
  {
    key: AccessControlLevel.PUBLIC,
    text: t('label.public-resource'),
    help: t('helpText.public'),
    type: MessageBarType.warning,
    style: { root: { display: 'flex' }, help: publicStyles },
  },
]

const ResourceAccess = (props: Props) => {
  const { access, users, updateAccess, isV2Enabled, disabled, uuid = '', boxWrap = false } = props
  const accessOptions: IChoiceGroupOption[] = accessLevels.map(
    ({ key, text, help, type, style }) => {
      return {
        key: key,
        text: text,
        onRenderField: (props, render) => {
          return (
            <div style={style.root}>
              {render!(props)}
              <MessageBar
                messageBarType={type}
                styles={getAccessLevel(access) === key ? style.help : { root: { display: 'none' } }}
              >
                {help}
              </MessageBar>
            </div>
          )
        },
        styles: { choiceFieldWrapper: { width: '100%' } },
      }
    },
  )
  const userMap = users.reduce((acc, user) => {
    acc[getFullName(user)] = user.bbUuid.toString()
    return acc
  }, {} as { [key: string]: string })

  const opts = Object.keys(userMap)
    .map(fullName => ({
      key: userMap[fullName],
      text: fullName,
    }))
    .filter(opt => opt.key !== uuid)

  if (isV2Enabled) {
    return boxWrap ? <ContentCard style={{ paddingTop: 0 }}>{renderV2()}</ContentCard> : renderV2()
  }

  return (
    <Stack.Item>
      <Label required={true}>{t('label.access')}</Label>
      <ChoiceGroup
        options={accessOptions}
        defaultSelectedKey={AccessControlLevel.PUBLIC}
        value={getAccessLevel(access)}
        onChange={(_e, option) =>
          option &&
          updateAccess(
            mapAccessObjectToUiAccess(getDefaultAccess(option.key as AccessControlLevel, uuid)),
          )
        }
        disabled={disabled}
        styles={{
          root: { display: 'inline-block', width: '100%' },
          flexContainer: { whiteSpace: 'nowrap' },
        }}
      />
    </Stack.Item>
  )

  function renderV2() {
    return (
      <>
        <Stack>
          <h4 style={{ margin: '0.5em 0' }}>{t('label.individual-access')}</h4>
          <MultiCombobox
            name="individualAccess"
            label={t('label.individuals')}
            placeholder={t('placeholder.select-individual-access')}
            values={Object.keys(access.individualAccess)
              .map(uuid => opts.find(opt => opt.key === uuid)?.text)
              .filter((text): text is string => text !== undefined)}
            options={opts}
            disable={disabled}
            comboBoxProps={{ allowFreeform: false }}
            onRenderValue={renderIndividual}
            onJoinValues={(_, values) =>
              updateAccess(updatedAccessLevel('individualAccess', mapToIndividualAccess(values)))
            }
            onRemoveValue={(_, values) =>
              updateAccess(updatedAccessLevel('individualAccess', mapToIndividualAccess(values)))
            }
            infoMessage={
              Object.keys(access.individualAccess).filter(key => key !== uuid).length > 0
                ? t('info.individual-ovveride')
                : ''
            }
          />
        </Stack>
        <Stack.Item>
          <h4 style={{ marginBottom: '0.5em' }}>{t('label.group-access')}</h4>
          <AccessField
            defaultSelectedKey={access.businessUserAccess}
            label={t('label.business')}
            userRole="business"
            handleChange={(_, value) =>
              updateAccess(updatedAccessLevel('businessUserAccess', value))
            }
            disabled={disabled}
            style={{ margin: 0 }}
          />
          <AccessField
            defaultSelectedKey={access.lawyerAccess}
            label={t('label.lawyer')}
            userRole="lawyer"
            handleChange={(_, value) => updateAccess(updatedAccessLevel('lawyerAccess', value))}
            disabled={disabled}
          />
        </Stack.Item>
      </>
    )
  }

  function mapToIndividualAccess(values: string[]) {
    const currentIndividualAccess = access.individualAccess
    const newAccess: { [key: string]: ResourceAccessLevel } = {
      [uuid]: currentIndividualAccess[uuid],
    }
    values.forEach(v => {
      const uuid = userMap[v]
      newAccess[uuid] = currentIndividualAccess[uuid] ?? ResourceAccessLevel.READ_ONLY
    })
    return newAccess
  }

  function getAccessLevel(access: UiAccessObject) {
    return isPrivate(createAccessObject(access))
      ? AccessControlLevel.PRIVATE
      : AccessControlLevel.PUBLIC
  }

  function updatedAccessLevel(
    key: string,
    value: ResourceAccessLevel | { [x: string]: ResourceAccessLevel },
  ) {
    return { ...access, [key]: value }
  }

  function updatedIndividualAccessLevel(uuid: string, accessLevel: ResourceAccessLevel) {
    return updatedAccessLevel('individualAccess', {
      ...access.individualAccess,
      [uuid]: accessLevel,
    })
  }

  function renderIndividual(
    key: string,
    value: string,
    removeValue: (i: string, e: never[]) => void,
  ) {
    const user = users.find(user => getFullName(user) === value)
    if (!user) return <></>

    const role = authorizerToAppRoleMapping[user.role as AuthorizorRole] || t('label.N/A')

    return (
      <AccessField
        key={key}
        index={key}
        uuid={user.bbUuid.toString()}
        userRole={user?.role}
        defaultSelectedKey={access.individualAccess[user.bbUuid] || ResourceAccessLevel.READ_ONLY}
        label={`${value} (${role})`}
        disabled={disabled}
        handleChange={(uuid, accessLevel) =>
          updateAccess(updatedIndividualAccessLevel(uuid, accessLevel))
        }
        removeValue={removeValue}
      />
    )
  }
}

export default ResourceAccess
