import { useContext, useEffect, useState } from 'react'
import { Stack } from '@fluentui/react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import TopNav from '@components/TopNav'
import KeywordSearch from '@components/KeywordSearch'
import StyledStack from '@components/StyledStack'
import QuickMessage from '@components/QuickMessage'
import { useContractTaskPaneViewed } from '@modules/analytics'
import { StyledDivider } from '@baseComponents/StyledDivider'
import { StoreContext } from '@contexts/StoreContext'
import { TemplatesContext } from '@contexts/TemplatesContext'
import AddTemplateForm from '@components/AddTemplateForm'
import SideCallout from '@components/SideCallout'
import TemplatesList from '@components/TemplatesList'
import FilterButton from '@components/FilterButton/FilterButton'

const pageTitle = 'Templates'

const TemplatesLibrary: React.FC = () => {
  const {
    query,
    error,
    setError,
    warn,
    Pagination,
    pageNum,
    topOfPageRef,
    loading,
    numFilters,
    templates,
    items,
    loadPage,
    search,
  } = useContext(TemplatesContext)
  // const { loadingMetadataConfig, metadataConfig } = useContext(KeyTermsContext)
  const { access } = useContext(StoreContext)
  const [addTemplateHidden, setAddTemplateHidden] = useState(true)
  const navigate = useNavigate()
  const { t } = useTranslation()

  useEffect(() => {
    setError('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templates.length])

  useEffect(() => {
    if (location.pathname.startsWith('/templates')) {
      loadPage(query)
      if (location.pathname === '/templates/filters') navigate('/templates')
    }
  })

  useContractTaskPaneViewed({
    pageTitle,
    eventDetails: [pageNum.toString()],
  })

  return (
    <div ref={topOfPageRef}>
      <TopNav
        title={pageTitle}
        showPrimaryButton={access.canCreateTemplate()}
        primaryButtonTooltip="Upload Template"
        onPrimaryButtonClick={() => setAddTemplateHidden(false)}
      />
      <StyledStack>
        <Stack.Item>
          <KeywordSearch
            query={query}
            loading={loading}
            search={search}
            placeholder={t('placeholder.templates-search')}
          />
          <FilterButton
            isLinkDisabled={
              loading || !items.count || !!(items.facets && items.facets.length === 0)
            }
            pathName="/templates/filters"
            numFilters={numFilters}
          />
          <StyledDivider />
          {renderTemplatesContent()}
        </Stack.Item>
      </StyledStack>
      <AddTemplateForm hidden={addTemplateHidden} toggleHidden={() => setAddTemplateHidden(true)} />
      {!loading && access.canViewCallout() && (
        <SideCallout
          buttonLabel={t('label.Bloomberg Law')}
          calloutTitle={t('label.Callout.additional-templates')}
          calloutText={t('label.Callout.additional-templates-message')}
          calloutLink="https://www.bloomberglaw.com/product/btic/search/results/dabe2a89a7145d5cee6441199c52401e"
          calloutLinkText={t('label.Callout.visit-templates')}
        />
      )}
    </div>
  )

  function renderTemplatesContent() {
    if (error) return <QuickMessage msg={error} type="error" />
    if (warn) return <QuickMessage msg={warn} type="warning" />
    if (!templates.length && !loading)
      return <QuickMessage msg={`0 ${t('label.template')}s found`} type="warning" />
    return (
      <Pagination>
        <TemplatesList
          label={t('label.contract-child-resources-list')}
          resources={templates}
          access={access}
          loading={loading}
          error={error}
        />
      </Pagination>
    )
  }
}

export default TemplatesLibrary
