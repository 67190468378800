import { useContext } from 'react'
import {
  ChoiceGroup,
  IChoiceGroupOption,
  MessageBar,
  MessageBarType,
  ProgressIndicator,
} from '@fluentui/react'

import { StyledDivider } from '@baseComponents/StyledDivider'
import LoadingShimmer from '@components/LoadingShimmer'
import SimilarClause from '@components/SimilarClause'
import StyledStack from '@components/StyledStack'
import { AnalysisContext } from '@contexts/AnalysisContext'
import { EDGAR, MY_CLAUSES } from '@modules/ClauseAnalyzer'
import { SimilarClausesContext } from '@contexts/SimilarClausesContext'
import FilterButton from '@components/FilterButton/FilterButton'

function SimilarClausesList() {
  const { analysisType, setAnalysisType, allowedAnalysisTypes, hasSelection } =
    useContext(AnalysisContext)

  const {
    loading,
    error,
    numFilters,
    items: { items, facets, type, count = 0 },
    selection,
  } = useContext(SimilarClausesContext)
  const isLoading = loading && hasSelection

  const choiceOptions: IChoiceGroupOption[] = [
    {
      key: MY_CLAUSES,
      text: 'Internal Company Clauses',
      disabled: allowedAnalysisTypes.indexOf(MY_CLAUSES) < 0,
      onRenderField: (props, render) => {
        return <div>{render && render(props)}</div>
      },
    },
    { key: EDGAR, text: 'Publicly Filed Contracts' },
  ]

  return (
    <StyledStack>
      {renderTop()}
      {isLoading && <ProgressIndicator label="Finding Similar Clauses..." />}
      {isLoading && <LoadingShimmer />}
      {count > 0 && renderList()}
      {!loading && !count && renderEmpty()}
      {error && renderError()}
    </StyledStack>
  )

  function renderTop() {
    return (
      <div>
        <ChoiceGroup
          label="Clause Resource"
          selectedKey={analysisType}
          options={choiceOptions}
          onChange={(_ev, option) => {
            option && setAnalysisType(option.key)
          }}
        />
        <StyledDivider />
        <FilterButton
          isLinkDisabled={
            loading || !!(facets?.length === 0) || selection.length === 0 || count === 0
          }
          pathName="/clauses/similar/filters"
          numFilters={numFilters}
        />
        <StyledDivider />
      </div>
    )
  }

  function renderList() {
    if (loading || !items) return <LoadingShimmer />

    return items.map(clause => {
      return (
        <div key={clause.id}>
          <SimilarClause clause={clause} type={type as string} />
          <StyledDivider style={{ marginTop: '1.5em' }} />
        </div>
      )
    })
  }

  function renderEmpty() {
    return (
      <MessageBar messageBarType={MessageBarType.info}>
        There are no similar clauses matching your selection. Please ensure you&apos;ve selected the
        entire clause or select a different clause in your document.
      </MessageBar>
    )
  }

  function renderError() {
    return <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>
  }
}

export default SimilarClausesList
