import { Dispatch, SetStateAction, useContext } from 'react'
import { MessageBar, MessageBarType, Text } from '@fluentui/react'

import { deleteBtnStyles } from '@modules/sharedStyles'
import { PlaybooksContext } from '@contexts/PlaybooksContext'
import ConfirmDialog from '@components/ConfirmDialog'
import BoldText from '@baseComponents/BoldText'

interface Props {
  id: string
  name: string
  hidden: boolean
  setDeleteModalHidden: Dispatch<SetStateAction<boolean>>
  onDeleted?: (id: string) => void
  error?: string
}

export default function DeletePlaybookForm(props: Props) {
  const { deleteSelectedPlaybook, deleting } = useContext(PlaybooksContext)

  return (
    <ConfirmDialog
      title="Confirm Delete"
      confirm="Delete Playbook"
      hidden={props.hidden}
      disableBtn={deleting}
      onConfirm={async () => {
        await deleteSelectedPlaybook(props.id)
        props.onDeleted?.(props.id)
      }}
      onDismiss={() => props.setDeleteModalHidden(true)}
      confirmBtnTitle="Delete Playbook"
      btnStyles={deleteBtnStyles}
    >
      <MessageBar messageBarType={MessageBarType.warning}>
        Once you delete a playbook from the system, you cannot retrieve it.
      </MessageBar>

      <Text style={{ display: 'block', marginTop: '1em' }}>
        Please confirm you want to delete the playbook:
        <BoldText> {props.name}</BoldText>.
      </Text>

      {props.error && (
        <MessageBar styles={{ root: { margin: '1em 0' } }} messageBarType={MessageBarType.error}>
          {props.error}
        </MessageBar>
      )}
    </ConfirmDialog>
  )
}
