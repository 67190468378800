import ApiClient from '@modules/ApiClient'
import Routes from './routes'
import { b64Encode } from './utils'

const apiClient = new ApiClient()
const routes = new Routes()

export default async function getPdfConversion(
  resourceType: string,
  resourceId: string,
  contractId?: string,
) {
  const params = { resourceType, resourceId, contractId }
  const contentUrl = await apiClient.get<{ url: string }>(routes.pdfConversionUrl, { params })

  const response = await apiClient.getBinaryContent<string>(contentUrl.data.url)
  const content = response.data

  return await Word.run(async context => {
    const encoded = b64Encode(content)
    const doc = context.application.createDocument(encoded)
    context.load(doc)
    doc.open()
    await context.sync()
  })
}
