import { useContext } from 'react'
import { PrimaryButton, Stack } from '@fluentui/react'
import { useNavigate } from 'react-router-dom'

import { useContractTaskPaneViewed } from '@modules/analytics'
import StyledStack from '@components/StyledStack'
import { StoreContext } from '@contexts/StoreContext'
import { useTranslation } from 'react-i18next'

const pageTitle = 'TrialAgreement'

const text = (
  <div>
    <h3>BLOOMBERG INDUSTRY GROUP CUSTOMER AGREEMENT</h3>
    <h4>(Bloomberg Law Contract Solutions Limited Trial)</h4>
    <p>
      This BLOOMBERG INDUSTRY GROUP Customer Agreement (&quot;Agreement&quot;) is between BLOOMBERG
      INDUSTRY GROUP, INC., 1801 S. Bell Street, Arlington, VA 22202 (referred to herein as
      &quot;Bloomberg Industry Group&quot;) and Customer, and is effective as of your acceptance
      below.
    </p>
    <p>
      <strong>1. License.</strong> Bloomberg Industry Group grants to Customer a non-exclusive,
      non-transferable, limited right to access and use the Microsoft Word plug-in for the Bloomberg
      Law Contract Solutions product, including any associated software applications, modules,
      documentation, and updates that Bloomberg Industry Group, in its sole discretion, makes
      available to Customer (collectively, the &quot;Product&quot;) during the applicable term.
      Customer agrees to and shall comply with the terms of this Agreement and any other applicable
      terms and conditions governing website access set forth on and referenced as part of the Terms
      of Service designated by Bloomberg Industry Group from time to time, as posted on the
      Product&apos;s webpage.
    </p>
    <p>
      <strong>2. Authorized Users.</strong> The Product may only be accessed by you during the
      applicable term (&quot;User&quot;). You represent that you are an employee of Customer. Access
      and use by any other third party, or any automated process, is not permitted.
    </p>
    <p>
      <strong>3. Copyright.</strong> The Product contains proprietary content and/or software
      protected by copyright and other similar laws. Bloomberg Industry Group and its licensors
      retain all rights in the Product, including (without limitation) all copyright and other
      proprietary rights worldwide in all media.
    </p>
    <p>
      <strong>4. Permitted Uses.</strong> Users may access and use the Product (and the content
      contained therein) solely for their own internal business purposes, and solely for testing
      purposes. Users shall not share User names and/or passwords. Customer agrees to notify
      Bloomberg Industry Group promptly in writing (via email to salessupport@bloombergindustry.com)
      of any known or suspected unauthorized use of the Product or any known or suspected breach of
      security, including but not limited to, loss, theft, or unauthorized disclosure of User names
      and passwords. Users may not distribute any amount of content from or derived from the Product
      in connection with this trial, other than to other employees of Customer for the sole purpose
      of evaluating whether to license the Product.
    </p>
    <p>
      <strong>5. Restrictions.</strong> In no event may Customer or User download, copy or
      distribute the Product in its entirety or substantial portions thereof, or systematically,
      routinely or regularly, download, copy and/or distribute copies of individual articles,
      charts/graphs/visuals, table of contents, indexes, search/alert results, summaries,
      newsletters, or other Bloomberg Industry Group finding aids or other content, even if Customer
      holds a copyright license with a third party licensing service (e.g., Copyright Clearance
      Center). Except as otherwise expressly permitted herein, Customer and Users may not reproduce,
      create derivative works from, perform, publish, transmit, distribute, sell (or participate in
      any sale), or otherwise access, use, or exploit any content and/or material retrieved from or
      contained in the Product in any manner whatsoever; store any content and/or materials from the
      Product in any information storage and retrieval system (except for a back-up system in which
      content or materials from the Product are accessible only by Users or Customer&apos;s IT
      personnel); distribute the content and/or materials contained in the Product to any person who
      is not duly authorized to use or receive the Product; distribute, rent, sublicense, lease,
      transfer or assign the Product or this Agreement; decompile, disassemble, or otherwise
      reverse-engineer the Product, or alter, translate, modify, or adapt the Product to create
      derivative works; make use of &quot;framing&quot; or other means of redirecting content; place
      or install any portion of the Product on any electronic media, including, but not limited to,
      local or wide area networks or intranets, multiple processing units, multiple site
      arrangements, service or software rental bureaus, list servers, online services, electronic
      bulletin boards or forums, Web sites, or any other server that is Internet-enabled.
      Notwithstanding anything to the contrary in this Agreement, Customer and Users (a) may not use
      or distribute, and may not permit any third party to use or distribute, the Product, content
      and/or materials in any manner that could, in Bloomberg Industry Group&apos;s good faith
      judgment, cause the Product, content and/or materials so used to (i) be a substitute for, or
      affect Bloomberg Industry Group&apos;s or its affiliates ability to realize revenue in
      connection with, the Product, content and/or materials or (ii) compete with the business of
      Bloomberg Industry Group or its affiliates and (b) agree to comply with all requirements of
      third-party data providers with respect to the content and materials sourced by all such third
      parties. Bloomberg Industry Group reserves the right periodically to audit and monitor
      (physically or electronically) the use of the Product to ensure compliance with the terms
      herein and to maintain and improve the provision of the Product. Notwithstanding anything to
      the contrary in this Agreement, if Bloomberg Industry Group believes, in its sole good faith
      judgment, that any provision of this Agreement has been breached by Customer or User,
      Bloomberg Industry Group reserves the right to suspend access to and use of the Product, or
      any portion thereof, at any time without notice effective immediately.
    </p>
    <p>
      <strong>6. Disclaimer of Professional Advice.</strong> The information provided through the
      Product is not and shall not be construed as tax, accounting, legal, regulatory or other
      professional advice or sufficient to satisfy any tax, accounting, legal, regulatory or other
      professional requirements. Customer and/or User should consult tax, accounting, legal,
      regulatory or other professional advisor(s) for advice. Neither the Product nor any related
      services or any portion thereof shall constitute or be construed as forming an attorney-client
      relationship.
    </p>
    <p>
      <strong>7. Representations; Limited Warranty.</strong>
    </p>
    <ol>
      <li>
        Customer represents and warrants that its use of the Product shall comply with all
        applicable laws, rules and regulations.
      </li>
      <li>
        EXCEPT AS SPECIFICALLY PROVIDED IN THIS AGREEMENT, THE PRODUCT IS PROVIDED TO CUSTOMER
        &quot;AS IS.&quot; BLOOMBERG INDUSTRY GROUP AND ITS AFFILIATES AND THEIR SUPPLIERS EXPRESSLY
        DISCLAIM ALL WARRANTIES, INCLUDING THE WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A
        PARTICULAR PURPOSE, AND DISCLAIM ALL RESPONSIBILITY FOR ANY LOSS OR CLAIM OF ANY KIND
        RELATING IN ANY WAY TO THE USE OF THE PRODUCT AND ANY CONTENT CONTAINED THEREIN, EVEN IF
        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. CUSTOMER SHALL INDEMNIFY, HOLD HARMLESS AND AT
        CUSTOMER&apos;S EXPENSE DEFEND BLOOMBERG INDUSTRY GROUP AND ITS AFFILIATES AND THEIR
        SUPPLIERS AGAINST ANY AND ALL LOSSES, CLAIMS, DEMANDS OR EXPENSES (INCLUDING REASONABLE
        ATTORNEYS&apos; FEES) ARISING OUT OF OR IN ANY WAY RELATED TO THE USE OF THE PRODUCT OR ANY
        CONTENT CONTAINED THEREIN.
      </li>
      <li>
        Customer acknowledges that the Product (and the content contained therein) is highly
        proprietary in nature and agrees that unauthorized copying, transfer or use may cause
        Bloomberg Industry Group, its affiliates and their suppliers irreparable injury that cannot
        be adequately compensated for by means of monetary damages. Customer agrees that Bloomberg
        Industry Group and its affiliates may enforce any breach of this Agreement by Customer or
        User by means of equitable relief (including, but not limited to, injunctive relief) in
        addition to any other available rights and remedies. Unauthorized reproduction, transfer,
        and/or use may be a violation of criminal as well as civil law.
      </li>
    </ol>
    <p>
      <strong>8. Limitation of Liability.</strong> IN NO EVENT SHALL BLOOMBERG INDUSTRY GROUP, ITS
      AFFILIATES AND THEIR OFFICERS, DIRECTORS, EMPLOYEES, SHAREHOLDERS, AGENTS OR REPRESENTATIVES
      (&quot;BLOOMBERG INDUSTRY GROUP ENTITIES&quot;) HAVE ANY RESPONSIBILITY OR LIABILITY,
      CONTINGENT OR OTHERWISE, FOR ANY INJURY OR DAMAGES AND/OR BE LIABLE TO CUSTOMER, USER, OR ANY
      OTHER PERSON FOR ANY LOST PROFITS, LOSSES, SPECIAL, INDIRECT, INCIDENTAL, EXEMPLARY OR
      CONSEQUENTIAL DAMAGES OR LOSS OF GOODWILL IN ANY WAY RELATING TO THE USE OF THE PRODUCT OR THE
      PERFORMANCE OR NON-PERFORMANCE OF ANY OBLIGATIONS UNDER THIS AGREEMENT, INCLUDING THE FAILURE
      OF ESSENTIAL PURPOSE, EVEN IF CUSTOMER, USER, OR OTHER PERSON HAS BEEN NOTIFIED OF THE
      POSSIBILITY OR LIKELIHOOD OF SUCH DAMAGES OCCURRING. SOME STATES DO NOT ALLOW THE LIMITATION
      OR EXCLUSION OF IMPLIED WARRANTIES OR LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO
      THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO CUSTOMER OR USER. BLOOMBERG INDUSTRY
      GROUP ENTITIES&apos; LIABILITY FOR DAMAGES UNDER THIS AGREEMENT TO CUSTOMER, USER, OR ANY
      OTHER PERSON SHALL IN ANY EVENT NOT EXCEED ONE HUNDRED U.S. DOLLARS ($100). IN NO EVENT MAY
      CUSTOMER OR USER BRING ANY CLAIM OR CAUSE OF ACTION AGAINST THE BLOOMBERG INDUSTRY GROUP
      ENTITIES MORE THAN ONE (1) YEAR AFTER SUCH CLAIM OR CAUSE OF ACTION ARISES.
    </p>
    <p>
      <strong>9. Term and Termination.</strong> Bloomberg Industry Group may terminate this
      Agreement and/or the trial access to the Product, without notice, at any time and for any or
      no reason. Paragraphs 3, 5, 6, 7, 8, 9, 10 and 11 shall survive any termination of this
      Agreement and shall continue in full force and effect.
    </p>
    <p>
      <strong>10. Notices.</strong> Unless otherwise specified herein, all notices to Bloomberg
      Industry Group under this Agreement shall be made in writing; sent via certified mail, return
      receipt requested, or a nationally recognized overnight courier service; effective upon
      receipt at the address stated below; and addressed to General Counsel, 1801 S. Bell Street,
      Arlington, VA 22202.
    </p>
    <p>
      <strong>11. Miscellaneous Provisions.</strong>
    </p>
    <ol>
      <li>
        <strong>No Waiver.</strong> Should Bloomberg Industry Group or Customer fail to exercise or
        enforce any provision of this Agreement or to waive any rights in respect thereto, such
        waiver or failure shall not be construed as constituting a continuing waiver or waiver of
        any other right.
      </li>
      <li>
        <strong>Choice of Law.</strong> This Agreement shall for all purposes be governed and
        construed in accordance with the law of the Commonwealth of Virginia without regard to its
        choice-of-law rules. The parties, and their successors and assigns, agree to submit to the
        jurisdiction of each of the federal and state courts located in Arlington County, Virginia,
        in connection with any matters arising out of or relating to this Agreement, and waive any
        objection to such venue, including forum non conveniens, sovereign immunity, Act of State or
        analogous doctrines.
      </li>
      <li>
        <strong>Entire Agreement.</strong> This Agreement, which may be amended by Bloomberg
        Industry Group from time to time, constitutes the entire agreement between Customer and
        Bloomberg Industry Group, and supersedes all prior or contemporaneous writings, discussions,
        agreements, and understandings of any kind, with respect to the subject matter of this
        Agreement.
      </li>
      <li>
        <strong>Severability.</strong> If any provision of this Agreement is held to be
        unenforceable, the parties shall renegotiate those provisions in good faith to be valid,
        enforceable substitute provisions, which provisions shall reflect as closely as possible the
        intent of the original provisions of this Agreement. If the parties fail to negotiate a
        substitute provision, this Agreement will continue in full force and effect without that
        provision and will be interpreted to reflect the original intent of the parties.
      </li>
      <li>
        <strong>Third Party Beneficiaries.</strong> All beneficial rights (other than the right to
        collect fees) granted to or reserved in this Agreement by Bloomberg Industry Group,
        including limited warranty, limitation of liability, remedies, indemnification,
        confidentiality, and ownership, shall accrue to and are for the benefit of licensors and
        suppliers to the same extent as Bloomberg Industry Group. Except as expressly stated herein,
        nothing contained in this Agreement is intended to create third party beneficiaries thereof.
      </li>
      <li>
        <strong>Each Party Acting Independently.</strong> Bloomberg Industry Group and Customer
        agree that each is acting independently of the other, that they are not joint venturers, and
        that neither is an agent, partner, or joint venturer of the other.
      </li>
      <li>
        <strong>Amendment and Assignment.</strong> Except as otherwise expressly set forth herein,
        this Agreement may not be changed, modified or amended except by a writing signed by a duly
        authorized representative of Bloomberg Industry Group and the Customer. Neither party may
        assign this Agreement or any rights or obligations created under this Agreement without the
        prior written consent of the other party, which consent will not be unreasonably withheld,
        except that Bloomberg Industry Group may assign this Agreement without consent (i) to any
        subsidiary or affiliated company, (ii) to an entity succeeding to all or substantially all
        of its stock or assets, whether by merger or purchase, provided that such entity shall
        expressly assume all of Bloomberg Industry Group&apos;s obligations under the Agreement, or
        (iii) in the event Bloomberg Industry Group sells or otherwise transfers the Product to a
        third party. Subject to the foregoing, this Agreement will be binding upon and inure to the
        benefit of the parties&apos; successors and assigns.
      </li>
      <li>
        <strong>Government Customers.</strong> The Product furnished hereunder is a commercial
        off-the-shelf product. Accordingly, (a) there is no data first produced in the performance
        of a government contract, and (b) all rights in data shall be solely as set forth in this
        Agreement.
      </li>
    </ol>
    <p>
      <em>Revised December 2024</em>
    </p>
  </div>
)

export default function TrialAgreement() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { access } = useContext(StoreContext)

  useContractTaskPaneViewed({ pageTitle })

  return (
    <StyledStack style={{ paddingTop: 0 }}>
      <Stack.Item>{text}</Stack.Item>
      <Stack.Item
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <PrimaryButton
          text={t('button.I understand')}
          role="link"
          onClick={() => {
            access.setShowTrialPage(false)
            navigate('/')
          }}
        />
      </Stack.Item>
    </StyledStack>
  )
}
