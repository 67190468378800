import { useState } from 'react'

import { MIMETYPE_DOCX, type RESOURCETYPES } from '@blaw/contracts-api-schema'
import { webappTemplateView, webappVersionPath } from '@modules/WebappPaths'
import openDocument from '@modules/openDocument'
import { navigateToExternalSite } from '@modules/utils'
import getPdfConversion from '@modules/PdfConversion'

// Used by ContractsList and ResourcesList to download and open documents
export default function useDocumentOpen() {
  const [loadingDocument, setLoadingDocument] = useState(false)
  const [openDocumentError, setOpenDocumentError] = useState<string>('')

  async function triggerDocumentOpen(
    documentId: string,
    contractId: string,
    versionId?: string,
    resourceType?: string,
  ) {
    setLoadingDocument(true)
    setOpenDocumentError('')

    try {
      await openDocument(documentId, contractId, versionId, resourceType)
    } catch (e: any) {
      console.error(e)
      if (e.code != 'OperationCancelled') {
        setOpenDocumentError((e as Error).message)
      }
      console.debug(e.debugInfo)
    } finally {
      setLoadingDocument(false)
    }
  }

  async function triggerPdfOpen(resourceType: string, resourceId: string, contractId?: string) {
    setLoadingDocument(true)
    setOpenDocumentError('')

    try {
      await getPdfConversion(resourceType, resourceId, contractId)
    } catch (e: any) {
      console.error(e)
      if (e.code != 'OperationCancelled') {
        setOpenDocumentError((e as Error).message)
      }
      console.debug(e.debugInfo)
    } finally {
      setLoadingDocument(false)
    }
  }

  async function openDocumentHandler(
    documentId: string,
    contractId: string,
    mimeType: string,
    versionId = 'latest',
    resourceType: RESOURCETYPES = 'document',
    editInWord = false,
  ) {
    try {
      if (editInWord) {
        if (mimeType === MIMETYPE_DOCX) {
          return await triggerDocumentOpen(documentId, contractId, versionId, resourceType)
        } else {
          return await triggerPdfOpen(resourceType, documentId, contractId)
        }
      }

      return resourceType === 'document'
        ? navigateToExternalSite(webappVersionPath(documentId || contractId, versionId))
        : navigateToExternalSite(webappTemplateView(contractId))
    } catch (e) {
      console.error(e)
    }
  }

  return {
    loadingDocument,
    openDocumentError,
    triggerDocumentOpen,
    setOpenDocumentError,
    triggerPdfOpen,
    openDocumentHandler,
  }
}
