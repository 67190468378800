export default function LocalStorage() {
  return new PartitionedStorage()
}

// https://learn.microsoft.com/en-us/office/dev/add-ins/develop/persisting-add-in-state-and-settings#storage-partitioning
class PartitionedStorage {
  storage: Storage

  constructor() {
    try {
      this.storage = window.localStorage
    } catch (e) {
      console.error(e)
      this.storage = nullStorage
    }
  }

  getItem(key: string) {
    return this.storage.getItem(`${this.#partition}${key}`)
  }

  setItem(key: string, value: string) {
    this.storage.setItem(`${this.#partition}${key}`, value)
  }

  removeItem(key: string) {
    this.storage.removeItem(`${this.#partition}${key}`)
  }

  clear() {
    this.storage.clear()
  }

  get #partition() {
    try {
      return Office.context.partitionKey
    } catch (e) {
      console.warn((e as Error).message)
      return 'blcs-word-addin-'
    }
  }
}

const nullStorage = {
  key: () => '',
  length: 0,
  clear: () => null,
  getItem: () => {
    console.warn('getItem called on null Storage')
    return ''
  },
  setItem: () => {
    console.warn('setItem called on null Storage')
  },
  removeItem: () => {
    console.warn('removeItem called on null Storage')
  },
}
