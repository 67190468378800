import { CSSProperties } from 'react'
import { SharedColors } from '@fluentui/react'

type Props = {
  mark?: string
  style?: CSSProperties
}

export default function RequiredFieldMark({ style, mark = '*' }: Props) {
  return <span style={{ ...style, color: SharedColors.red20 }}>{mark}</span>
}
