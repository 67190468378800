import { useState } from 'react'
import {
  ActionButton,
  IComboBoxOption,
  IComboBoxProps,
  IOnRenderComboBoxLabelProps,
  IRenderFunction,
  ITextFieldProps,
  MessageBar,
  MessageBarType,
  Stack,
} from '@fluentui/react'

import { entries } from '@modules/utils'
import ComboboxField from '@components/ComboboxField'

export interface MultiComboboxProps<V extends string> extends ITextFieldProps {
  values: V[]
  options: IComboBoxOption[]
  onJoinValues: (name: string, values: V[]) => void
  label: string
  name: string
  disable?: boolean
  labeledBy?: string
  comboBoxProps?: Partial<IComboBoxProps>
  onRenderValue?: (key: string, value: V, removeValue: RemoveValue<V>) => JSX.Element
  onRemoveValue?: (name: V, values: V[]) => void
  renderLabel?: IRenderFunction<IOnRenderComboBoxLabelProps>
  infoMessage?: string
}

type ValueStates<V extends string> = Record<string, V>
export type RemoveValue<V extends string> = (key: string, emptyVal: V[]) => void

const defaultEmptyVal = ['']

export default function MultiCombobox<V extends string>({
  name,
  label,
  values,
  options,
  disable,
  labeledBy,
  renderLabel,
  onJoinValues,
  onRenderValue,
  onRemoveValue,
  comboBoxProps,
  infoMessage,
  placeholder,
}: MultiComboboxProps<V>) {
  const [newOptions, setNewOptions] = useState<IComboBoxOption[]>(options)
  const [valueStates, setValueStates] = useState(buildValueStates(values))
  const renderValue = onRenderValue ?? defaultRender

  return (
    <>
      <Stack
        horizontal
        tokens={{ childrenGap: '0.7em' }}
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        <ComboboxField
          style={{ container: { flexGrow: 1 } }}
          label={label}
          labeledBy={labeledBy}
          onRenderLabel={renderLabel}
          options={newOptions}
          selectedKeys={Object.keys(valueStates)}
          text={''}
          placeholder={placeholder}
          multiselect
          onChange={(_event, option, _index, value) =>
            joinValues(option?.key.toString(), value as V)
          }
          disabled={disable}
          comboBoxProps={comboBoxProps}
        />
      </Stack>

      {infoMessage && (
        <MessageBar messageBarType={MessageBarType.info} styles={{ root: { margin: '0.5em 0' } }}>
          {infoMessage}
        </MessageBar>
      )}
      {entries(valueStates).map(([key, value]) => renderValue(key, value, removeValue))}
    </>
  )

  function defaultRender(key: string, value: V, removeValue: RemoveValue<V>) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
        key={key}
      >
        <span
          style={{
            flexGrow: 1,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {value}
        </span>
        <ActionButton
          iconProps={{ iconName: 'cancel' }}
          title="Remove this field"
          onClick={() => removeValue(key, defaultEmptyVal as V[])}
        />
      </div>
    )
  }

  function buildValueStates(values: V[]) {
    const vals = values.length === 1 && values[0] === '' ? [] : [...values]

    return vals.reduce((states, value) => {
      const idx = options.find(option => option.text === value)?.key
      states[`${idx}`] = value
      return states
    }, {} as ValueStates<V>)
  }

  function findKeyByValue(value: V) {
    for (const option of Object.values(newOptions)) {
      if (option.text === value) return option.key.toString()
    }
  }

  function joinValues(key: string | undefined, value: V | undefined) {
    if (!value) return

    // key can be undefined if value was typed in instead of selecting so check if it exists already
    if (!key) key = findKeyByValue(value)

    // remove if already selected
    if (key && Object.values(valueStates).includes(value)) {
      removeValue(key, defaultEmptyVal as V[])
      return
    }

    // add to options list on freeform input
    if (!key) {
      key = Object.keys(newOptions).length.toString()
      setNewOptions([...newOptions, { key: key, text: value }])
    }
    const newValues = { ...valueStates, [key]: value }
    const newValue: V[] = Object.values(newValues)

    setValueStates(newValues)
    onJoinValues(name, newValue)
  }

  function removeValue(key: string, emptyVal: V[]) {
    const value = valueStates[key]
    delete valueStates[key]
    const newValues = { ...valueStates }
    const newValue: V[] = Object.values(newValues)

    setValueStates(newValues)
    onJoinValues(name, newValue)
    onRemoveValue?.(value, newValue.length ? newValue : emptyVal)
  }
}
