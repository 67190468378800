import { FormEvent, useContext, useState } from 'react'
import {
  ChoiceGroup,
  DefaultButton,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack,
} from '@fluentui/react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { itemClicked, useContractTaskPaneViewed } from '@modules/analytics'
import {
  PlaybooksComplianceRequest,
  PlaybooksComplianceResponse,
  ComplianceStatusResponse,
} from '@blaw/contracts-api-schema'
import { useTranslation } from '@hooks/useTranslation'
import AddAnotherTextField from '@components/AddAnotherTextField'
import ContractTitle from '@baseComponents/ContractTitle'
import StyledStack from '@components/StyledStack'
import TopNav from '@components/TopNav'
import { getDocBodyText } from '@modules/wordDocument'
import ApiClient from '@modules/ApiClient'
import { StoreContext } from '@contexts/StoreContext'
import BoldText from '@baseComponents/BoldText'
import { contractOriginBaseOptions } from '@modules/Contract'
import { KeyTermsContext } from '@contexts/KeyTermsContext'
import LoadingShimmer from '@components/LoadingShimmer'
import { delay } from '@modules/utils'
import ModalProgress from '@components/ModalProgress'

const pageTitle = 'Compliance'

// TODO: move to schema
type ContractOrigins = 'INTERNAL' | 'EXTERNAL'
const DEFAULT_ORIGIN = 'INTERNAL'

export default function NewPlaybookCompliance() {
  const { storeSessionInfo, checkingLoginStatus, routes } = useContext(StoreContext)
  const { loadingMetadataConfig, metadataConfig } = useContext(KeyTermsContext)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [guidance, setGuidance] = useState([''])
  const [contractOrigin, setContractOrigin] = useState<ContractOrigins>(DEFAULT_ORIGIN)
  const [submitting, setSubmitting] = useState(false)
  const [hideProgress, setHideProgress] = useState(true)
  const [error, setError] = useState('')
  const [params] = useSearchParams()
  const playbookId = useParams().playbookId ?? t('label.N/A')
  const playbookTitle = params.get('title') ?? t('label.N/A')
  const playbookType = params.get('type') ?? t('label.N/A')
  const partyRole = params.get('role') ?? t('label.N/A')
  const isLoading = submitting || loadingMetadataConfig

  useContractTaskPaneViewed({
    pageTitle,
    eventDetails: [playbookId, playbookTitle, playbookType],
  })

  return (
    <>
      <TopNav title={pageTitle} prevPath={() => navigate(-1)} showAIGeneratedBadge />
      <MessageBar messageBarType={MessageBarType.info}>
        Verify that the document you are currently working on is compliant with this playbook.
      </MessageBar>

      {loadingMetadataConfig && <LoadingShimmer size={5} style={{ margin: '1em 0.5em' }} />}
      {!loadingMetadataConfig && (
        <form onSubmit={submit}>
          <StyledStack>
            <Stack.Item>
              <ContractTitle title={playbookTitle} />
            </Stack.Item>

            <Stack.Item>
              <BoldText>{metadataConfig?.getLabel('contract_type')}: </BoldText>
              {playbookType}
            </Stack.Item>

            <Stack.Item>
              <BoldText>Party Role: </BoldText>
              {partyRole}
            </Stack.Item>

            <Stack.Item style={{ marginBottom: '0.5em' }}>
              <ChoiceGroup
                value={contractOrigin}
                options={contractOriginBaseOptions}
                label={metadataConfig?.getLabel('first_draft_origin')}
                defaultSelectedKey={contractOrigin}
                disabled={isLoading}
                onChange={(_e, option) =>
                  setContractOrigin((option?.key as ContractOrigins) ?? DEFAULT_ORIGIN)
                }
              />
            </Stack.Item>

            <Stack.Item>
              <AddAnotherTextField
                autoFocus
                multiline
                values={guidance}
                disabled={isLoading}
                labelPrefix={t('label.Deal Specific Instructions')}
                addBtnLabel={t('label.Add another instruction')}
                placeholder={t('placeholder.Deal Specific Instructions')}
                onValueChange={setGuidance}
              />
            </Stack.Item>

            <Stack.Item style={{ display: 'flex', gap: '0.5em' }}>
              <PrimaryButton type="submit" disabled={isLoading} style={{ flexGrow: 1 }}>
                {t('button.Check Compliance')}
              </PrimaryButton>
              <DefaultButton onClick={() => navigate(-1)}>{t('button.Cancel')}</DefaultButton>

              <ModalProgress
                title="Running Playbook"
                description={`The ${t(
                  'page.Playbooks Compliance Summary.subtitle',
                )} will be displayed in a few minutes...`}
                hidden={hideProgress}
                error={error}
                cancelBtn={error ? 'Close' : false}
                onDismiss={() => setHideProgress(true)}
              />
            </Stack.Item>
          </StyledStack>
        </form>
      )}
    </>
  )

  async function submit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    if (!playbookId) return setError('Missing Playbook ID')

    itemClicked({
      pageTitle: pageTitle,
      itemClicked: t('button.Check Compliance'),
      itemLocation: 'Taskpane',
      itemType: 'Form Submit',
      isLoggedIn: true,
    })

    try {
      const apiClient = new ApiClient(storeSessionInfo, setError, checkingLoginStatus)
      const contract = await getDocBodyText()

      const payload: PlaybooksComplianceRequest = {
        playbookId,
        contract,
        guidance,
        contractOrigin,
      }

      setSubmitting(true)
      setHideProgress(false)
      setError('')

      const { data } = await apiClient.post<PlaybooksComplianceResponse>(
        routes.playbooksComplianceUrl,
        payload,
      )
      let complianceStatus: ComplianceStatusResponse = { completed: false }
      const interval = 5000 // 5 second interval

      while (!(complianceStatus.completed && complianceStatus.content)) {
        const { data: status } = await apiClient.get<ComplianceStatusResponse>(
          routes.getPlaybooksComplianceUrl(data.requestId),
        )
        complianceStatus = status
        if (!status.completed) await delay(interval)
      }
      navigate('/playbooks/compliance/summary', { state: complianceStatus.content })
      setHideProgress(true)
    } catch (e) {
      console.error(e)
      setError((e as Error).message)
    } finally {
      setSubmitting(false)
    }
  }
}
