export const defaultActionsMenuItems = [
  {
    key: 'viewInWeb',
    text: 'View in Web App',
    iconProps: { iconName: 'OpenInNewWindow' },
  },
  {
    key: 'editInWord',
    text: 'Edit',
    iconProps: { iconName: 'PageEdit' },
  },
]
